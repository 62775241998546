import React, { useRef } from "react";
import "./ContactUsUserDetailModel.scss";

import CustomInput from "../../../common/CustomInput";
import { AppImages } from "../../../../Images/AppImages";
 
export default function ContactUsUserDetailModel({
  userData,

  closeModel,
}) {
  const ref = useRef(null);

  const { name, message, email } = userData;

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      closeModel();
    }
  };

  return (
    <div className="contact-us-user-Details-model" onClick={handleClickOutside}>
      <div className="wrapper">
        <div ref={ref} className="user-details">
          <p className="section_title">Contact Us User Detail</p>
          <div className="titleAndDescription">
            <div className="width_100">
              <CustomInput label="User Name" disabled={true} value={name} />
            </div>
            <div className="width_100">
              <CustomInput label="Email" disabled={true} value={email} />
            </div>
          </div>
          <div className="message-conatiner">
            <div className="label">User Message</div>
            <p className="message-data ">{message}</p>
          </div>
        </div>
        <button onClick={() => closeModel?.()} className="cross">
          <img src={AppImages.cross} alt="" />
        </button>
      </div>
    </div>
  );
}
