import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./creditCardUserEntriesData.scss";

import DataTable from "react-data-table-component";
import { MdRemoveRedEye } from "react-icons/md";
import Loader from "../../common/Loader";
import Searchbar from "../../common/SearchBar";

import toast from "react-hot-toast";
import { AppImages } from "../../../Images/AppImages";
import {
  deleteCreditCardUserEntry,
  getAllCreditCardsUsersEntries,
} from "../../../store/CreditCardUsersEntriesSlice";
import DeleteModel from "../../common/DeleteModel";

import { getCreditCardCategories } from "../../../store/creditCardPageSlice";
import DropDownIdValue from "../../common/DropDownIdValue";
import UserDetailModel from "../Model/UserDetailModel";
const userTypes = [
  { _id: "All users", name: "All users" },
  { _id: "Credit card users", name: "Credit card users" },
  { _id: "Banner users", name: "Banner users" },
];
export default function CreditCardUserEntriesData() {
  const [openUserDetailModel, setOpenUserDetailModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [usersUpdated, setUsersUpdated] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [creditCardCategoriesData, setCreditCardCategoriesData] = useState([]);
  const [seletedUserType, setSelectedUserType] = useState({
    value: "All users",
    label: "All users",
  });

  const [deleteSelectedUser, setDeleteSelectedUser] = useState(null);
  const [selectdUserData, setselectdUserData] = useState(null);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    getAllCreditCardsUsersEntriesLoader,

    deleteLoading,
    allCreditCardsUsersEntriesData,
  } = useSelector((state) => state.creditCardUsersEntriesReducer);
  const {
    creditCardData: { creditCardCategories },
  } = useSelector((state) => state.CreditCardPageReducer);

  const deleteUserEntry = () => {
    dispatch(deleteCreditCardUserEntry(deleteSelectedUser._id)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("User Deleted succesfully !");
        setUsersUpdated((prev) => !prev);
        setOpenDeleteModel(false);
        setDeleteSelectedUser(null);
        return;
      }
      toast.error("Something went wrong, please try again !");
      setOpenDeleteModel(false);
      setDeleteSelectedUser(null);
    });
  };

  useEffect(() => {
    let filterData = [...tableData];

    const searched = filterData?.filter(
      ({ first_name, last_name, email }) =>
        first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        email.toLowerCase().includes(searchQuery.toLowerCase())
    );
    filterData = searched;

    // if (seletedUserType?.value === "All users") {
    //   filterData = filterData;
    // } else

    if (seletedUserType?.value === "Credit card users") {
      filterData = filterData?.filter((item) => !item?.is_banner);
    } else if (seletedUserType?.value === "Banner users") {
      filterData = filterData?.filter((item) => item?.is_banner === true);
      setSelectedCategory(null);
      setSelectedSubCategory(null);
    }

    if (selectedCategory?.value) {
      filterData = filterData.filter((item) => {
        if (
          item?.creditcardId &&
          item?.creditcardId.category === selectedCategory?.value
        ) {
          return true;
        }
        return false;
      });
    }
    if (selectedCategory?.value && selectedSubCategory?.value) {
      filterData = filterData.filter((item) => {
        if (
          item?.creditcardId &&
          item?.creditcardId.category === selectedCategory?.value &&
          item?.creditcardId.subcategory === selectedSubCategory?.value
        ) {
          return true;
        }
        return false;
      });
    } else {
      filterData = filterData;
    }
    setFilterTableData([...filterData]);
  }, [
    tableData,
    searchQuery,
    selectedCategory,
    selectedSubCategory,
    selectedCategory?.value,
    selectedSubCategory?.value,
    setSelectedUserType,
    seletedUserType?.value,
  ]);

  useEffect(() => {
    dispatch(getAllCreditCardsUsersEntries());
  }, [usersUpdated]);
  useEffect(() => {
    dispatch(getCreditCardCategories());
  }, []);

  useEffect(() => {
    setCreditCardCategoriesData(creditCardCategories);
  }, [creditCardCategories]);
  useEffect(() => {
    setTableData(allCreditCardsUsersEntriesData);
    setFilterTableData(allCreditCardsUsersEntriesData);
    setSearchQuery("");
  }, [allCreditCardsUsersEntriesData]);

  const columns = useMemo(
    () => [
      {
        name: "First name",
        selector: (row) => row?.first_name,
        sortable: true,
      },
      {
        name: "Last name",
        selector: (row) => row?.last_name,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row?.email,
        sortable: true,
      },

      {
        name: "Actions",
        cell: (row) => (
          <div className="viewDelete">
            <button
              className="viewBtn"
              onClick={() => {
                setselectdUserData(row);
                setOpenUserDetailModel(true);
              }}
            >
              <MdRemoveRedEye size={20} />
            </button>
            <button
              onClick={() => {
                setDeleteSelectedUser(row);
                setOpenDeleteModel(true);
              }}
              className="deleteBtn"
            >
              <img src={AppImages.deleteBin} />
            </button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    [tableData]
  );

  return (
    <>
      <div className="userDetailsSection">
        <div className="head">
          <p className="section_title">All Users Entries</p>
          <div>
            <Searchbar value={searchQuery} onchange={setSearchQuery} />
          </div>
        </div>
        <div className="table">
          <div className="dropDown-container">
            <DropDownIdValue
              label={"User Type"}
              options={userTypes}
              name="category"
              handleChange={(e) => {
                setSelectedUserType(e.target.value);
              }}
            />
            <DropDownIdValue
              label={"Category"}
              isDisabled={
                seletedUserType?.value === "Banner users" ? true : false
              }
              options={creditCardCategoriesData}
              name="category"
              value={
                selectedCategory
                  ? {
                      value: selectedCategory.value,
                      label: selectedCategory?.label,
                    }
                  : null
              }
              handleChange={(e) => {
                setSelectedCategory(e.target.value);
                setSelectedSubCategory(null);
              }}
            />

            <DropDownIdValue
              label={"Sub category"}
              isDisabled={
                seletedUserType?.value === "Banner users" ? true : false
              }
              value={
                selectedSubCategory
                  ? {
                      value: selectedSubCategory.value,
                      label: selectedSubCategory?.label,
                    }
                  : null
              }
              options={
                creditCardCategoriesData?.find(
                  ({ _id }) => _id === selectedCategory?.value
                )?.subcategories
              }
              name="subcategory"
              handleChange={(e) => {
                console.log(e);
                setSelectedSubCategory(e.target.value);
              }}
            />
          </div>
          {getAllCreditCardsUsersEntriesLoader ? (
            <div className="loader">
              <Loader />
            </div>
          ) : tableData?.length ? (
            <DataTable columns={columns} data={filterTableData} pagination />
          ) : (
            <div className="data-notfound">Data not Found</div>
          )}
        </div>
      </div>
      {openUserDetailModel && (
        <UserDetailModel
          userData={selectdUserData}
          closeModel={() => setOpenUserDetailModel(false)}
        />
      )}
      {openDeleteModel && (
        <DeleteModel
          message={
            <>
              Are you sure you want to delete{" "}
              <b>'{deleteSelectedUser?.first_name}'</b> user?
            </>
          }
          loading={deleteLoading}
          deleteCallback={deleteUserEntry}
          closeModel={() => setOpenDeleteModel(false)}
        />
      )}
    </>
  );
}
