import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import toast from "react-hot-toast";
import AboutUsBanner from "../../components/aboutUsComponents/AboutUsBanner";
import OurMissions from "../../components/aboutUsComponents/OurMissions";
import Button from "../../components/common/Button";
import PageTitle from "../../components/common/PageTitle";
import { SendAboutUsPageData, getAboutUsPageData } from "../../store/aboutUsSlice";

const AboutUsPage = () => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { aboutUsData, loading } = useSelector(
    (state) => state.aboutUsPageReducer
  );
console.log()
  useEffect(() => {
    dispatch(getAboutUsPageData());
  }, [dispatch]);

  useEffect(() => {
    setData(aboutUsData);
  }, [aboutUsData]);

  const validate = (updatedData = data) => {
    
    let tempErrors = {};

    if (!updatedData.aboutus_description?.trim())
      tempErrors.aboutus_description = "Description is required.";
    if (!updatedData.aboutus_image)
      tempErrors.aboutus_image = "Image is required.";
    else if (
      typeof updatedData.aboutus_image === "object" &&
      !updatedData.aboutus_image?.type?.startsWith("image/")
    ) {
      tempErrors.aboutus_image = "File is not a valid image type.";
    }

    if (updatedData.mission) {
      updatedData.mission.forEach((mission, index) => {
        if (!mission.title?.trim())
          tempErrors[`mission[${index}][title]`] = "Title is required.";
        if (!mission.description?.trim())
          tempErrors[`mission[${index}][description]`] =
            "Description is required.";
        if (!mission.image)
          tempErrors[`mission[${index}][image]`] = "Image is required.";
        else if (
          typeof mission?.image === "object" &&
          !mission?.image?.type?.startsWith("image/")
        ) {
          tempErrors[`mission[${index}][image]`] =
            "File is not a valid image type.";
        }
      });
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e, newValue, arrayName, index, fieldName) => {
    const { name, value } = e.target;

    let updatedData;
    if (arrayName && index !== undefined && fieldName) {
      const updatedArray = [...data[arrayName]];
      updatedArray[index] = {
        ...updatedArray[index],
        [fieldName]: newValue || value,
      };
      updatedData = { ...data, [arrayName]: updatedArray };
    } else {
      updatedData = { ...data, [name]: value };
    }

    setData(updatedData);
    validate(updatedData);
  };

  const handleSubmit = () => {
    if (validate()) {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("aboutus_image", data.aboutus_image);
      formData.append("aboutus_description", data.aboutus_description?.trim());

      for (let i = 0; i < data?.mission?.length; i++) {
        formData.append(`mission[${i}][title]`, data.mission[i]?.title?.trim());
        formData.append(
          `mission[${i}][description]`,
          data.mission[i]?.description?.trim()
        );
        formData.append(`mission[${i}][image]`, data.mission[i]?.image);
      }

      // for (let i = 0; i < data?.work?.length; i++) {
      //   formData.append(`work[${i}][title]`, data.work[i]?.title?.trim());
      //   formData.append(`work[${i}][description]`, data.work[i]?.description?.trim());
      //   formData.append(`work[${i}][image]`, data.work[i]?.image);
      // }

      dispatch(SendAboutUsPageData(formData)).then((res) => {
        if (res.payload.status && res.payload.status === 200) {
          toast.success("Data updated succesfully !");
        }
      });
    }
  };

  return (
    <React.Fragment>
      <PageTitle title="Home Page" />
      <AboutUsBanner handleChange={handleChange} data={data} errors={errors} />
      <OurMissions handleChange={handleChange} data={data} errors={errors} />
      <Button onClick={handleSubmit} loading={loading}>
        Save
      </Button>
    </React.Fragment>
  );
};

export default AboutUsPage;
