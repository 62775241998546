import React from "react";
import "./adminLayout.scss";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import PageWrapper from "../../components/common/PageWrapper";

export default function AdminLayout({ children }) {
  return (
    <div id="adminLayout">
      <Sidebar />
      <div className="mainBox">
        {/* <Navbar /> */}
        <PageWrapper>{children}</PageWrapper>
      </div>
    </div>
  );
}
