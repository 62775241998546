import { combineReducers } from "@reduxjs/toolkit";
import pageReducer from "./pageReducer";
import userReducer from "./userReducer";
import homePageReducer from "./homePageSlice";
import CreditCardPageReducer from "./creditCardPageSlice";
import creditCardUsersEntriesReducer from "./CreditCardUsersEntriesSlice";
import userSubscriptionsReducer from "./userSubscriptionslice";
import aboutUsPageReducer from "./aboutUsSlice";
import footerReducer from "./footerSlice";
import discoverReducer from "./discoverSlice";
import popupReducer from "./popupSlice";
import guidesReducer from "./guidePageSlice";
import contactUsReducer from "./contactUsSlice";
const rootReducer = combineReducers({
  userReducer,
  pageReducer,
  homePageReducer,
  CreditCardPageReducer,
  creditCardUsersEntriesReducer,
  userSubscriptionsReducer,
  aboutUsPageReducer,
  footerReducer,
  discoverReducer,
  popupReducer,
  guidesReducer,
  contactUsReducer
});

export default rootReducer;
