 import React, { useState } from "react";
 import CustomInput from "../../common/CustomInput";
import "./ourMission.scss"
 const OurMissions = ({ handleChange, data, errors }) => {
   const [previews, setPreviews] = useState([]);

   const handleImageChange = (e, index) => {
     const file = e.target.files[0];
     if (file) {
       const reader = new FileReader();
       reader.onloadend = () => {
         setPreviews((prevPreviews) => {
           const newPreviews = [...prevPreviews];
           newPreviews[index] = reader.result;
           return newPreviews;
         });
       };
       reader.readAsDataURL(file);
       handleChange(e, file, "mission", index, "image");
     }
   };

   return (
     <div className="main_section_our_mission">
       <p className="section_title">Our mission Section</p>
       {data?.mission &&
         data?.mission.map((missionSingle, index) => {
           return (
             <>
               <p className="listInputTitle">Mission {index + 1}</p>
               <div className="titleAndDescription">
                 <div className="width_50">
                   <CustomInput
                     label="Title"
                     name={`mission[${index}][title]`}
                     isCompulsory={true}
                     handleChange={(e) =>
                       handleChange(e, undefined, "mission", index, "title")
                     }
                     value={missionSingle.title}
                     error={errors[`mission[${index}][title]`]}
                   />
                 </div>

                 <div className="width_50">
                   <CustomInput
                     label="Description"
                     isCompulsory={true}
                     handleChange={(e) =>
                       handleChange(
                         e,
                         undefined,
                         "mission",
                         index,
                         "description"
                       )
                     }
                     value={missionSingle.description}
                     error={errors[`mission[${index}][description]`]}
                   />
                 </div>

                 <div className="width_50">
                   <CustomInput
                     label="Image"
                     type="file"
                     isCompulsory={true}
                     handleChange={(e) => handleImageChange(e, index)}
                     error={errors[`mission[${index}][image]`]}
                   />
                   {(previews[index] || missionSingle.image) && (
                     <img
                       src={
                         previews[index] ? previews[index] : missionSingle.image
                       }
                       alt="Preview"
                       className="img_preview"
                     />
                   )}
                 </div>
               </div>
             </>
           );
         })}
     </div>
   );
 };

 export default OurMissions;
