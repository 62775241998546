import React from "react";
import CategorySection from "../../components/CreditCardComponents/CategorySection";
import CreditCardBanner from "../../components/CreditCardComponents/CreditCardBanner";
import CreditCardsSection from "../../components/CreditCardComponents/CreditCardsSection";
import PageTitle from "../../components/common/PageTitle";

const CreditCard = () => {
  return (
    <React.Fragment>
      <PageTitle title="Credit Card" />
      <CreditCardBanner />
      <CategorySection />
      <CreditCardsSection/>
    </React.Fragment>
  );
};

export default CreditCard;
