import axiosInstance from "../utils/axiosInstance";
import { API_ROUTES } from "./apiRoutes";

export const login = async (body) => {
  try {
    console.log(body);
    const response = await axiosInstance.post(API_ROUTES.LOGIN, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getPageList = async () => {
  try {
    const response = await axiosInstance.get(API_ROUTES.GET_PAGE_LIST);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateSection = async (sectionId, data) => {
  try {
    console.log("sectionid : ",sectionId)
    const response = await axiosInstance.put(API_ROUTES.UPDATE_SECTION+sectionId, data);
    return response;
  } catch (error) {
    return error.response.data;
  }
};


