import React, { useEffect, useState } from "react";
import "./mapLink.scss";
import CustomInput from "../../common/CustomInput";
import Button from "../../common/Button";
import { useDispatch, useSelector } from "react-redux";
 
import toast from "react-hot-toast";
import { SendcontactUsMapLinkDataData, getContactUsMapLinkData } from "../../../store/contactUsSlice";
export default function MapLink() {
 
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});

  const { contactUsMapLinkDataLoading: loading, contactUsMapLinkData } =
    useSelector((state) => state.contactUsReducer);
  console.log(data);
  useEffect(() => {
    dispatch(getContactUsMapLinkData());
  }, []);

  useEffect(() => {
    setData(contactUsMapLinkData);
  }, [contactUsMapLinkData]);

  const validate = (updatedData = data) => {
    let tempErrors = {};
    if (!updatedData.contactus_link?.trim())
      tempErrors.contactus_link = "Map Link  is required.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleSubmit = (e) => {
      e?.preventDefault();
      const formData = new FormData();

      formData.append("name", data?.name?.trim());
      formData.append("contactus_link", data?.contactus_link?.trim());
      
    if (validate()) {
      dispatch(SendcontactUsMapLinkDataData(formData)).then((res) => {
        if (res.payload.status && res.payload.status === 200) {
          toast.success("Data updated succesfully !");
        }
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedData = { ...data, [name]: value };

    setData(updatedData);
    validate(updatedData);
  };
  console.log(data);
 
  return (
    <form onSubmit={handleSubmit} className="main_section_map_link">
      <p className="section_title">Map Link</p>
      <div className="titleAndDescription">
        <div className="width_50">
          <CustomInput
            label="Contact Us Map Link"
            name="contactus_link"
            isCompulsory={true}
            handleChange={handleChange}
            value={data?.contactus_link}
            error={errors.contactus_link}
          />
        </div>
      </div>
      <Button type="submit" className="btn" loading={loading}>
        Save
      </Button>
    </form>
  );
}
