import React, { useState } from "react";
import CustomInput from "../../common/CustomInput";

const OurExploreSection = ({ handleChange, data, errors }) => {
  return (
    <div className="main_section_img_title">
      <p className="section_title">Explore Section</p>
      {data?.explore &&
        data?.explore.map((exploreSingle, index) => {
          return (
            <>
              <p
                className="listInputTitle"
                
              >
                Explore {index + 1}
              </p>
              <div className="titleAndDescription">
                <div className="width_50">
                  <CustomInput
                    label="Title"
                    
                    isCompulsory={true}
                    name={`explore[${index}][title]`}
                    handleChange={(e) =>
                      handleChange(e, undefined, "explore", index, "title")
                    }
                    value={exploreSingle.title}
                    error={errors[`explore[${index}][title]`]}
                  />
                </div>

                <div className="width_50">
                  <CustomInput
                    label="Description"
                    isCompulsory={true}
                    handleChange={(e) =>
                      handleChange(
                        e,
                        undefined,
                        "explore",
                        index,
                        "description"
                      )
                    }
                    name="explore"
                    value={exploreSingle.description}
                    error={errors[`explore[${index}][description]`]}
                  />
                </div>

                {/* <div className="width_50">
                  <CustomInput
                    label="Button Link"
                    name={`explore_button_link${index}`}
                    handleChange={(e) =>
                      handleChange(
                        e,
                        undefined,
                        "explore",
                        index,
                        "button_link"
                      )
                    }
                    value={exploreSingle?.button_link}
                    error={errors[`explore[${index}][button_link]`]}
                  />
                </div> */}
              </div>
            </>
          );
        })}
    </div>
  );
};

export default OurExploreSection;
