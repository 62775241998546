import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import CustomInput from "../../components/common/CustomInput";
import { login } from "../../services";
import { loginUser } from "../../store/userReducer";
import { customHandleChange } from "../../utils/helper";
import { isEmailValid, isPasswordValid } from "../../utils/validation";
import "./login.scss";
import PasswordInputField from "../../components/common/PasswordInputField";
// import appLogo from "../../Images/applogo.svg";
const Login = () => {
  const dispatch = useDispatch();
  const initialErr = {
    email: "",
    password: "",
  };
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(initialErr);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { isUserLogedIn } = useSelector((state) => state.userReducer);
  const handleLogin = async (e) => {
    e.preventDefault();

    const emailErr = isEmailValid(loginData.email);
    const passErr = isPasswordValid(loginData.password);
    if (emailErr || passErr) {
      setError({
        email: emailErr,
        password: passErr,
      });
      return;
    }
    setLoader(true);
    const response = await login(loginData);

    if (response.status === 200) {
      // console.log(response);
      setLoader(false);

      dispatch(loginUser({ token: response.data.token }));
      toast.success("Login successfull");
      navigate("/home");
    } else {
      setLoader(false);
      // console.log(response.message);
      toast.error(response.message);
    }
  };
  if (isUserLogedIn) {
    return <Navigate to={"/home"} />;
  }
  return (
    <form onSubmit={handleLogin} className="main-box">
       
        {/* <div className="logo_scott">
          <img src={appLogo} alt="" />
        </div> */}
       
      <h1 className="title">Login</h1>
      <CustomInput
        type="text"
        placeholder="Email"
        name="email"
        value={loginData.email}
        error={error?.email}
        label="Email ID"
        handleChange={(e) =>
          customHandleChange(
            "email",
            e.target.value,
            loginData,
            setLoginData,
            error,
            setError,
            isEmailValid
          )
        }
      />
      <div className={"margin-top"}>
        <PasswordInputField
          placeholder="Password"
          name="password"
          value={loginData.password}
          error={error?.password}
          label="Password"
          handleChange={(e) =>
            customHandleChange(
              "password",
              e.target.value,
              loginData,
              setLoginData,
              error,
              setError,
              isPasswordValid
            )
          }
        />
      </div>

      {/* <Link className="forgot-link" to="/forgot-password">
        Forgot Password?
      </Link> */}

      <Button type={"submit"} className="loginBtn" loading={loader}>
        Login
      </Button>
    </form>
  );
};

export default Login;
