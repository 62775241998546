import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getDiscoverHeroData,
  sendDiscoverHeroData,
} from "../../../store/discoverSlice";
import Button from "../../common/Button";
import CustomInput from "../../common/CustomInput";
import "./heroContent.scss";
const HeroContent = () => {
  const [previews, setPreviews] = useState([]);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { heroDiscoverPageData, heroDiscoverPageDataLoading: loading } =
    useSelector((state) => state.discoverReducer);

  console.log();
  useEffect(() => {
    dispatch(getDiscoverHeroData());
  }, [dispatch]);

  useEffect(() => {
    setData(heroDiscoverPageData);
  }, [heroDiscoverPageData]);

  const validate = (updatedData = data) => {
    let tempErrors = {};

    if (!updatedData.discover_maintitle?.trim())
      tempErrors.discover_maintitle =
        " Main Discover Credit Card Title  is required.";
    if (!updatedData.banefit_title?.trim())
      tempErrors.banefit_title = "Benefits of Credit Analysis  is required.";

    if (updatedData.discover) {
      updatedData.discover.forEach((discover, index) => {
        if (!discover.title?.trim())
          tempErrors[`discover[${index}][title]`] = "Title is required.";
        if (!discover.description?.trim())
          tempErrors[`discover[${index}][description]`] =
            "Description is required.";
        if (!discover.image)
          tempErrors[`discover[${index}][image]`] = "Image is required.";
        else if (
          typeof discover?.image === "object" &&
          !discover?.image?.type?.startsWith("image/")
        ) {
          tempErrors[`discover[${index}][image]`] =
            "File is not a valid image type.";
        }
      });
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e, newValue, arrayName, index, fieldName) => {
    const { name, value } = e.target;

    let updatedData;
    if (arrayName && index !== undefined && fieldName) {
      const updatedArray = [...data[arrayName]];
      updatedArray[index] = {
        ...updatedArray[index],
        [fieldName]: newValue || value,
      };
      updatedData = { ...data, [arrayName]: updatedArray };
    } else {
      updatedData = { ...data, [name]: value };
    }

    setData(updatedData);
    validate(updatedData);
  };

  const handleSubmit = () => {
    if (validate()) {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("banefit_title", data.banefit_title?.trim());
      formData.append("discover_maintitle", data.discover_maintitle?.trim());

      for (let i = 0; i < data?.discover?.length; i++) {
        formData.append(
          `discover[${i}][title]`,
          data.discover[i]?.title?.trim()
        );
        formData.append(
          `discover[${i}][description]`,
          data.discover[i]?.description?.trim()
        );
        formData.append(`discover[${i}][image]`, data.discover[i]?.image);
      }

      dispatch(sendDiscoverHeroData(formData)).then((res) => {
        if (res.payload.status && res.payload.status === 200) {
          toast.success("Data updated succesfully !");
        }
      });
    }
  };
  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviews((prevPreviews) => {
          const newPreviews = [...prevPreviews];
          newPreviews[index] = reader.result;
          return newPreviews;
        });
      };
      reader.readAsDataURL(file);
      handleChange(e, file, "discover", index, "image");
    }
  };

  return (
    <div className="main_section_our_discover">
      <p className="section_title">Hero discover Section</p>

      <div className="titleAndDescription margin-bottom">
        <div className="width_50">
          <CustomInput
            label="Hero Page  Title "
            name={`discover_maintitle`}
            isCompulsory={true}
            handleChange={handleChange}
            value={data.discover_maintitle}
            error={errors?.discover_maintitle}
          />
        </div>
        <div className="width_50">
          <CustomInput
            label="Benefits of Credit Analysis Title"
            name={"banefit_title"}
            isCompulsory={true}
            handleChange={handleChange}
            value={data.banefit_title}
            error={errors?.banefit_title}
          />
        </div>
      </div>
      {data?.discover &&
        data?.discover.map((discoverSingle, index) => {
          return (
            <>
              <p className="listInputTitle">Discover {index + 1}</p>
              <div className="titleAndDescription">
                <div className="width_50">
                  <CustomInput
                    label="Title"
                    name={`discover[${index}][title]`}
                    isCompulsory={true}
                    handleChange={(e) =>
                      handleChange(e, undefined, "discover", index, "title")
                    }
                    value={discoverSingle.title}
                    error={errors[`discover[${index}][title]`]}
                  />
                </div>

                <div className="width_50">
                  <CustomInput
                    label="Description"
                    isCompulsory={true}
                    handleChange={(e) =>
                      handleChange(
                        e,
                        undefined,
                        "discover",
                        index,
                        "description"
                      )
                    }
                    value={discoverSingle.description}
                    error={errors[`discover[${index}][description]`]}
                  />
                </div>

                <div className="width_50">
                  <CustomInput
                    label="Image"
                    type="file"
                    isCompulsory={true}
                    handleChange={(e) => handleImageChange(e, index)}
                    error={errors[`discover[${index}][image]`]}
                  />
                  {(previews[index] || discoverSingle.image) && (
                    <img
                      src={
                        previews[index] ? previews[index] : discoverSingle.image
                      }
                      alt="Preview"
                      className="img_preview"
                    />
                  )}
                </div>
              </div>
            </>
          );
        })}

      <Button onClick={handleSubmit} loading={loading}>
        Save
      </Button>
    </div>
  );
};

export default HeroContent;
