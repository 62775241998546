import React from "react";
 
import PageTitle from "../../components/common/PageTitle";
import MapLink from "../../components/ContactUsComponents/MapLink";
import UserInformation from "../../components/ContactUsComponents/UserInformation";
 

const ContactUsPage = () => {
  return (
    <React.Fragment>
      <PageTitle title="Credit Card" />
 <MapLink/>
 <UserInformation/>
    </React.Fragment>
  );
};

export default ContactUsPage;
