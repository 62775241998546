import React, { useEffect, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { SendHomePageData, getHomePageData } from "../../store/homePageSlice";
import HeroBannerSection from "../../components/HomePageComponents/heroBannerSection";
import ExclusiveCardSection from "../../components/HomePageComponents/exclusiveCardSection";

import OurGuideSection from "../../components/HomePageComponents/ourGuideSection";
import OurServiceSection from "../../components/HomePageComponents/ourServiceSection";
import OurExploreSection from "../../components/HomePageComponents/exploreSection";
import toast from "react-hot-toast";

import Button from "../../components/common/Button";
// import AboutUs from "../../components/HomePageComponents/aboutUs";

const HomePage = () => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { homeData, loading } = useSelector((state) => state.homePageReducer);

  useEffect(() => {
    dispatch(getHomePageData());
  }, [dispatch]);

  useEffect(() => {
    setData(homeData);
  }, [homeData]);

  const validate = (updatedData = data) => {
   
    console.log(updatedData.card_image);
    let tempErrors = {};
    if (!updatedData.banner_title?.trim())
      tempErrors.banner_title = "Title is required.";
    if (!updatedData.banner_description?.trim())
      tempErrors.banner_description = "Description is required.";
    if (!updatedData.banner_image)
      tempErrors.banner_image = "Image is required.";
    else if (
      typeof updatedData.banner_image === "object" &&
      !updatedData.banner_image?.type?.startsWith("image/")
    )
      tempErrors.banner_image = "File is not a valid image type.";
    if (!updatedData.hero_button_link?.trim())
      tempErrors.hero_button_link = "Button link is required.";

    // if (!updatedData.aboutus_description?.trim())
    //   tempErrors.aboutus_description = "Description is required.";
    // if (!updatedData.aboutus_image)
    //   tempErrors.aboutus_image = "Image is required.";
    // else if (
    //   typeof updatedData.aboutus_image === "object" &&
    //   !updatedData.aboutus_image?.type?.startsWith("image/")
    // ) {
    //   tempErrors.aboutus_image = "File is not a valid image type.";
    // }

    if (!updatedData.card_title?.trim())
      tempErrors.card_title = "Card title is required.";
    if (!updatedData.card_image)
      tempErrors.card_image = "Card image is required.";
    else if (
      typeof updatedData.card_image === "object" &&
      !updatedData.card_image?.type?.startsWith("image/")
    )
      tempErrors.card_image = "File is not a valid image type.";

    if (!updatedData.exlusive_button_link?.trim())
      tempErrors.exlusive_button_link = "Button link is required.";
    if (!updatedData.guide_title?.trim())
      tempErrors.guide_title = "Guide title is required.";
    // if (!updatedData.read_our_button_link)
    //   tempErrors.read_our_button_link = "Button link is required.";

    if (updatedData.service) {
      updatedData.service.forEach((service, index) => {
        if (!service.title?.trim())
          tempErrors[`service[${index}][title]`] = "Service title is required.";
        if (!service.description?.trim())
          tempErrors[`service[${index}][description]`] =
            "Service description is required.";
        if (!service.button_link?.trim())
          tempErrors[`service[${index}][button_link]`] =
            "Service button link is required.";
      });
    }

    // if (updatedData.work) {
    //   updatedData.work.forEach((work, index) => {
    //     if (!work.title)
    //       tempErrors[`work[${index}][title]`] = "Title is required.";
    //     if (!work.description)
    //       tempErrors[`work[${index}][description]`] =
    //         "Description is required.";
    //     if (!work.image)
    //       tempErrors[`work[${index}][image]`] = "Image is required.";
    //   });
    // }

    if (updatedData.explore) {
      updatedData.explore.forEach((explore, index) => {
        if (!explore.title)
          tempErrors[`explore[${index}][title]`] = "Title is required.";
        if (!explore.description)
          tempErrors[`explore[${index}][description]`] =
            "Description is required.";
      });
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e, newValue, arrayName, index, fieldName) => {
    const { name, value } = e.target;

    let updatedData;
    if (arrayName && index !== undefined && fieldName) {
      const updatedArray = [...data[arrayName]];
      updatedArray[index] = {
        ...updatedArray[index],
        [fieldName]: newValue || value,
      };
      updatedData = { ...data, [arrayName]: updatedArray };
    } else {
      updatedData = { ...data, [name]: value };
    }

    setData(updatedData);
    validate(updatedData);
  };

  const handleSubmit = () => {
    if (validate()) {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("banner_description", data.banner_description?.trim());
      formData.append("banner_image", data.banner_image);
      formData.append("banner_title", data.banner_title?.trim());
      // formData.append("aboutus_image", data.aboutus_image);
      // formData.append("aboutus_description", data.aboutus_description?.trim());
      formData.append("card_image", data.card_image);
      formData.append("card_title", data.card_title?.trim());
      formData.append("guide_title", data.guide_title?.trim());
      formData.append("hero_button_link", data?.hero_button_link?.trim());
      formData.append(
        "exlusive_button_link",
        data?.exlusive_button_link?.trim()
      );
      // formData.append("read_our_button_link", data?.read_our_button_link);
      for (let i = 0; i < data?.explore?.length; i++) {
        formData.append(`explore[${i}][title]`, data.explore[i]?.title?.trim());
        formData.append(
          `explore[${i}][description]`,
          data.explore[i]?.description?.trim()
        );
        // formData.append(
        //   `explore[${i}][button_link]`,
        //   data.explore[i]?.button_link?.trim()
        // );
      }
      for (let i = 0; i < data?.service?.length; i++) {
        formData.append(`service[${i}][title]`, data.service[i]?.title?.trim());
        formData.append(
          `service[${i}][description]`,
          data.service[i]?.description?.trim()
        );
        formData.append(
          `service[${i}][button_link]`,
          data.service[i]?.button_link?.trim()
        );
      }
      // for (let i = 0; i < data?.work?.length; i++) {
      //   formData.append(`work[${i}][title]`, data.work[i]?.title);
      //   formData.append(`work[${i}][description]`, data.work[i]?.description);
      //   formData.append(`work[${i}][image]`, data.work[i]?.image);
      // }

      dispatch(SendHomePageData(formData)).then((res) => {
        if (res.payload.status && res.payload.status === 200) {
          toast.success("Data updated succesfully !");
        }
      });
    }
  };

  return (
    <React.Fragment>
      <PageTitle title="Home Page" />

      <HeroBannerSection
        handleChange={handleChange}
        data={data}
        errors={errors}
      />
      <ExclusiveCardSection
        handleChange={handleChange}
        data={data}
        errors={errors}
      />
      {/* <AboutUs handleChange={handleChange} data={data} errors={errors} /> */}
      <OurServiceSection
        handleChange={handleChange}
        data={data}
        errors={errors}
      />
      {/* <HowWeWorkSection
        handleChange={handleChange}
        data={data}
        errors={errors}
      /> */}
      <OurExploreSection
        handleChange={handleChange}
        data={data}
        errors={errors}
      />
      <OurGuideSection
        handleChange={handleChange}
        data={data}
        errors={errors}
      />
      {/* <button
        disabled={loading}
        onClick={handleSubmit}
        className="save_data_button"
      >
        {loading ? <Loader /> : "Save"}
      </button> */}
      <Button onClick={handleSubmit} loading={loading}>
        Save
      </Button>
    </React.Fragment>
  );
};

export default HomePage;
