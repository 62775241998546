import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { AdminRoutes } from './AdminRoutes';
import ProtectedRoutes from '../utils/ProtectRoutes';

export default function AppRoutes() {
  return (
    <Routes>
      {AdminRoutes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={
              route.protected ? (
                <ProtectedRoutes>
                  <route.layout>
                    <route.component />
                  </route.layout>
                </ProtectedRoutes>
              ) : (
                <route.layout>
                  <route.component />
                </route.layout>
              )
            }
          />
        );
      })}
    </Routes>
  )
}
