export function isEmailValid(email) {
  const pattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  email = email.trim();

  if (email.length === 0) {
    return "Please enter an email address.";
  }
  if (email.length > 320) {
    return "Email address must be 320 characters or less.";
  }
  if (!pattern.test(email)) {
    return "Please enter a valid email address.";
  }

  return "";
}

export function isPasswordValid(password) {
  const pattern = /^(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/gm; 
  if (password.length <= 0) return "Please Enter password";
  if (password.length < 6) return "password must be more than 6 letter";
  else return "";
}

export const isEmpty = (value)=>{
  if(value.trim().length <= 0){
    return "Field can not be empty!"
  }
  else{
    return ""
  }
}
