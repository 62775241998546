import React from "react";
import CustomInput from "../../common/CustomInput";
import "./frequentlyAskedQuestions.scss";
const FrequentlyAskedQuestions = ({ handleChange, data, errors }) => {
  return (
    <div className="main_section_faq">
      <p className="section_title">Frequently Asked Questions</p>
      <div className="titleAndDescription">
        <div className="width_50">
          <CustomInput
            label="FAQ Title"
            isCompulsory={true}
            name={`FAQ`}
            handleChange={handleChange}
            value={data?.FAQ}
            error={errors?.FAQ}
          />
        </div>
      </div>
      {data?.QA &&
        data?.QA.map((QASingle, index) => {
          return (
            <>
              <p className="listInputTitle">Question {index + 1}</p>

              <div className="titleAndDescription">
                <div className="width_50">
                  <CustomInput
                    label="Question"
                    isCompulsory={true}
                    name={`QA[${index}][question]`}
                    handleChange={(e) =>
                      handleChange(e, undefined, "QA", index, "question")
                    }
                    value={QASingle?.question}
                    error={errors[`QA[${index}][question]`]}
                  />
                </div>
                <div className="width_50">
                  <CustomInput
                    label="Answer"
                    isCompulsory={true}
                    handleChange={(e) =>
                      handleChange(e, undefined, "QA", index, "answer")
                    }
                    name={`QA[${index}][answer]`}
                    value={QASingle?.answer}
                    error={errors[`QA[${index}][answer]`]}
                  />
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default FrequentlyAskedQuestions;
