import React, { useState } from "react";
import "./passwordInputField.scss";
import { AppImages } from "../../../Images/AppImages";

export default function PasswordInputField({
  title,
  maxChar,
  isCompulsory = false,
  value,
  accept,
  autoFocus,
  handleChange,
  error,
  id,
  disabled,
  label,
  name,
  style,
  className,
  placeholder,
 
}) {
  const [inputType, setInputType] = useState("password");
  return (
    <div className="password-input-container-main">
      <label>
        {label}
        {isCompulsory && label && <span> *</span>}
      </label>
      <div className="input-field">
        <input
          value={value}
          title={title}
          maxLength={maxChar}
          autoFocus={autoFocus}
          className={` input-field ${className}`}
          type={inputType}
          accept={accept}
          onChange={handleChange}
          disabled={disabled}
          name={name}
          id={id}
          placeholder={placeholder}
          style={style}
        />
        <button
          type="button"
          onClick={() =>
            setInputType((prev) => (prev === "password" ? "text" : "password"))
          }
        >
          <img
            src={
              inputType === "password"
                ? AppImages.passwordHide
                : AppImages.passwordShow
            }
            alt=""
          />
        </button>
      </div>
      {error && <p className={`errmsg`}>{error}</p>}
    </div>
  );
}
