export const API_ROUTES = {
  LOGIN: "/admin/login/",
  GET_PAGE_LIST: "/admin/page/pagelist/",
  UPDATE_SECTION: "/admin/editSection/",
  GET_HOME_PAGE: "/admin/Home_page",
  PUT_HOME_PAGE: "/admin/editpage/Home_page",
  //category
  GET_CREDIT_CARD_CATEGORIES: "/admin/card/categories",
  GET_CREDIT_CARD_SUB_CATEGORIES_BY_CATEGORY_ID: "/admin/card/subcategories/",
  POST_CREDIT_CARD_CATEGORY: "/admin/addcategory",  
  POST_CREDIT_CARD_SUB_CATEGORY: "/admin/addsubcategory",  
  PUT_CREDIT_CARD_CATEGORY: "/admin/edit-category/", // ADD ID of category at the end of url
  PUT_CREDIT_CARD_SUB_CATEGORY: "/admin/edit-subcategory/", // ADD ID of category at the end of url
  DELETE_CREDIT_CARD_CATEGORY: "/admin/delete-category/",
  DELETE_CREDIT_CARD_SUB_CATEGORY: "/admin/delete-subcategory/",
  //credit card
  GET_CREDIT_CARD_BANNER: "/admin/Credit_card",
  PUT_CREDIT_CARD_BANNER: "/admin/editpage/Credit_card",
  POST_CREDIT_CARD: "/admin/addcreditcard",
  PUT_CREDIT_CARD: "/admin/edit-creditCard/",
  GET_CREDIT_CARD: "/admin/get-creditCard/",
  GET_ALL_CREDIT_CARDS: "/admin/card/creditCards",
  DELETE_CREDIT_CARD: "/admin/delete-creditCard/",
  
  // credit Card users Entries
  GET_ALL_CREDITCARD_USERS_ENTRIES: "/admin/user/getUsers",
  DELETE_CREDIT_CARD_USER_ENTRY: "/admin/deleteuser/",

  // user Subscriptions

  GET_ALL_USER_SUBSCRIPTION: "/admin/user/getAllsubscriptions",
  DELETE_USER_SUBSCRIPTION: "/admin/deleteSubscription/",
  
  //about us page
  GET_ABOUT_US_PAGE_DATA: "/admin/about_us" ,
  PUT_ABOUT_US_PAGE_DATA: "/admin/editpage/about_us",


  //footer 
  GET_FOOTER_DATA: "/admin/footer/getFooters",
  PUT_FOOTER_DATA: "admin/editfooter/",

  // Discover hero
  GET_HERO_DISCOVER_PAGE_DATA: "/admin/discover",
  PUT_HERO_DISCOVER_PAGE_DATA: "/admin/editpage/discover",
  
  //  discover page Benefits Of Credit Analysis 
  POST_BENEFIT: "/admin/add-benefit",
  GET_BENEFITS: "/admin/benefit/getbenefits",
  PUT_BENEFIT: "/admin/editbenefit/",
DELETE_BENEFIT:"/admin/deletebenefit/",
  

  // Popup
  GET_POPUP_DATA: "/admin/popup",
  PUT_POPUP_DATA: "/admin/editpage/popup" ,
  
  //Guide page  (tips&card_compare)

  GET_GUIDE_PAGE_DATA:"/admin/tips&card_compare",
  PUT_GUIDE_PAGE_DATA: "admin/editpage/tips&card_compare",
  
  // Contact Us
  GET_CONTACT_US_MAP_LINK_DATA: "/admin/ContactUs",
  PUT_CONTACT_US_MAP_LINK_DATA: "/admin/editpage/ContactUs",

  //contact us User Data
  GET_CONTACT_US_DATA: "/admin/contact/getContacts",
  DELETE_CONTACT_US_DATA: "/admin/deleteContact/",
  
};


