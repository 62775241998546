import Login from "../pages/Login";
import AuthLayout from "../layout/AuthLayout";
import AdminLayout from "../layout/AdminLayout";
import HomePage from "../pages/Home";
import CreditCard from "../pages/CreditCard";
import CreditCardUserEntries from "../pages/CreditCardUserEntries";
import UserSubScriptions from "../components/UserSubscriptionsComponents/UserSubScriptions";
import AboutUsPage from "../pages/AboutUs";
import Footer from "../pages/Footer";
import DiscoverPage from "../pages/Discover";
import Popup from "../pages/Popup";
import GuidesPage from "../pages/Guides";
import ContactUsPage from "../pages/ContactUs";

export const AdminPaths = {
  DEFAULT: "/",
  LOGIN: "/login",
  EXCLUSIVECARDS: "/exclusive-cards",
  OURSERVICES: "/our-services",
  HOWWEWORK: "/how-we-work",
  EXPLORE: "/explore",
  GUIDES: "/guides",
};

export const AdminRoutes = [
  { path: "/", layout: AuthLayout, component: Login },
  {
    path: "/home",
    layout: AdminLayout,
    component: HomePage,
    protected: true,
  },
  {
    path: "/creditcard",
    layout: AdminLayout,
    component: CreditCard,
    protected: true,
  },
  {
    path: "/creditcard-user-entries",
    layout: AdminLayout,
    component: CreditCardUserEntries,
    protected: true,
  },
  {
    path: "/user-subscription",
    layout: AdminLayout,
    component: UserSubScriptions,
    protected: true,
  },
  {
    path: "/user-about-us",
    layout: AdminLayout,
    component: AboutUsPage,
    protected: true,
  },
  {
    path: "/footer",
    layout: AdminLayout,
    component: Footer,
    protected: true,
  },
  {
    path: "/discover",
    layout: AdminLayout,
    component: DiscoverPage,
    protected: true,
  },
  {
    path: "/popup",
    layout: AdminLayout,
    component: Popup,
    protected: true,
  },
  {
    path: "/guides",
    layout: AdminLayout,
    component: GuidesPage,
    protected: true,
  },
  {
    path: "/contact-us",
    layout: AdminLayout,
    component: ContactUsPage,
    protected: true,
  },
  
  // {
  //   path: AdminPaths.EXCLUSIVECARDS,
  //   layout: AdminLayout,
  //   component: ExclusiveCards,
  //   protected: true,
  // },
  // {
  //   path: AdminPaths.OURSERVICES,
  //   layout: AdminLayout,
  //   component: OurServices,
  //   protected: true,
  // },
  // {
  //   path: AdminPaths.HOWWEWORK,
  //   layout: AdminLayout,
  //   component: HowWeWork,
  //   protected: true,
  // },
  // {
  //   path: AdminPaths.EXPLORE,
  //   layout: AdminLayout,
  //   component: Explore,
  //   protected: true,
  // },
  // {
  //   path: AdminPaths.GUIDES,
  //   layout: AdminLayout,
  //   component: Guides,
  //   protected: true,
  // },
];
