import React from "react";
import { Navigate } from "react-router-dom";
import { AdminPaths } from "../routes/AdminRoutes";
import { useSelector } from "react-redux";

const ProtectedRoutes = ({ children }) => {
  const { isUserLogedIn } = useSelector((state) => state.userReducer);

  return isUserLogedIn ? children : <Navigate to="/" replace />;
};

export default ProtectedRoutes;
