import React, { Children } from 'react'
import Loader from '../Loader';
import "./button.scss"
export default function Button({title, type="button",disabled,className="", onClick , loading, children }) {
  return (
    <button
      title={title}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
      className={"main_data_button " + className}
    >
      {loading ? <Loader /> : children}
    </button>
  );
}
