import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getCreditCardBannerData, sendCreditCardBannerData } from "../../../store/creditCardPageSlice";
import Button from "../../common/Button";
import CustomInput from "../../common/CustomInput";
import "./creditCardBanner.scss";
export default function CreditCardBanner() {
  const [previewImage, setPreviewImage] = useState(null);
  const dispatch = useDispatch();
 
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});

  const {
    creditCardData: { creditCardBannerData },
    creditCardBannerDataLoading:loading,
  } = useSelector((state) => state.CreditCardPageReducer);
console.log(data);
  useEffect(() => {
    dispatch(getCreditCardBannerData());
  }, []);

  useEffect(() => {
    setData(creditCardBannerData);
  }, [creditCardBannerData]);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        console.log(reader.result);
        setPreviewImage(reader.result);
        handleChange({ target: { name: "banner_image", value: file } });
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
      handleChange({ target: { name: "banner_image", value: null } });
    }
  };
 
  const validate = (updatedData = data) => {
    let tempErrors = {};
    if (!updatedData.banner_title?.trim())
      tempErrors.banner_title = "Title is required.";
    if (!updatedData.banner_description?.trim())
      tempErrors.banner_description = "Description is required.";
    if (!updatedData.banner_image)
      tempErrors.banner_image = "Image is required.";
    else if (
      typeof updatedData.banner_image === "object" &&
      !updatedData?.banner_image.type.startsWith("image/")
    )
      tempErrors.banner_image = "File is not a valid image type.";
    if (!updatedData.banner_link?.trim())
      tempErrors.banner_link = "Button link is required.";
 

      setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
    const handleSubmit = (e) => {
      e?.preventDefault()
    if (validate()) {
      const formData = new FormData();
      formData.append("banner_description", data.banner_description?.trim());
      formData.append("banner_image", data.banner_image);
      formData.append("banner_title", data.banner_title?.trim());
      formData.append("banner_link", data.banner_link?.trim());
      formData.append("name", "Credit_card");

        dispatch(sendCreditCardBannerData(formData)).then((res) => {
          console.log(res, "resssssponseeee");
          if (res.payload.status && res.payload.status === 200) {
            toast.success("Credit Card Banner updated succesfully !");
          }
        });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedData = { ...data, [name]: value };

    setData(updatedData);
    validate(updatedData);
  };
   console.log(data);
   console.log(previewImage);
  return (
    <form onSubmit={handleSubmit} className="main_section_credit_card_banner">
      <p className="section_title">Credit Card Banner</p>
      <div className="titleAndDescription">
        <div className="width_50">
          <CustomInput
            label="Title"
            name="banner_title"
            isCompulsory={true}
            handleChange={handleChange}
            value={data?.banner_title}
            error={errors.banner_title}
          />
        </div>

        <div className="width_50">
          <CustomInput
            label="Description"
            isCompulsory={true}
            handleChange={handleChange}
            name="banner_description"
            value={data?.banner_description}
            error={errors.banner_description}
          />
        </div>
        <div className="width_50">
          <CustomInput
            label="Image"
            type="file"
            isCompulsory={true}
            accept="image/*"
            style={{
              padding: "13px",
            }}
            handleChange={handleImageChange}
            name="banner_image"
            error={errors.banner_image}
          />

          {previewImage && previewImage.startsWith("data:") ? (
            data?.banner_image.type.startsWith("image/") ? (
              <img src={previewImage} alt="Preview" className="preview-image" />
            ) : null
          ) : data?.banner_image ? (
            <img
              src={data?.banner_image}
              alt="Preview"
              className="preview-image"
            />
          ) : null}
        </div>
        <div className="width_50">
          <CustomInput
            label="Button Link"
            isCompulsory={true}
            handleChange={handleChange}
            name="banner_link"
            value={data?.banner_link}
            error={errors.banner_link}
          />
        </div>
        {/* 
        <div className="width_50">
          <CustomInput
            label="Button Link"
            name="hero_button_link"
            handleChange={handleChange}
            value={data.hero_button_link}
            error={errors.hero_button_link}
          />
        </div> */}
      </div>
      <Button type="submit" className="btn" loading={loading}>
        Save
      </Button>
    </form>
  );
}
