import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROUTES } from "../services/apiRoutes";
import axiosInstance from "../utils/axiosInstance";

const initialState = {
    getAllUserSubscriptionDataLoading: false,
    allUserSubscriptionData: [],
    deleteLoading:false
};

export const getAllUserSubscriptionData = createAsyncThunk(
    "admin/getAllUserSubscriptionData",
    async (thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_ALL_USER_SUBSCRIPTION);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

export const deleteUserSubscription = createAsyncThunk(
    "admin/deleteUserSubscription",
    async (id, thunkAPI) => {
        try {
            const response = await axiosInstance.delete(API_ROUTES.DELETE_USER_SUBSCRIPTION+id);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const userSubscriptionslice = createSlice({
    name: "userSubscriptionslice",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAllUserSubscriptionData.pending, (state, action) => {
                state.getAllUserSubscriptionDataLoading = true;
            })
            .addCase(getAllUserSubscriptionData.fulfilled, (state, action) => {
                state.getAllUserSubscriptionDataLoading = false;
                state.allUserSubscriptionData = action.payload.data.data;
            })
            .addCase(getAllUserSubscriptionData.rejected, (state, action) => {
                state.getAllUserSubscriptionDataLoading = false;
            });

        builder
            .addCase(deleteUserSubscription.pending, (state, action) => {
                state.deleteLoading = true;
            })
            .addCase(deleteUserSubscription.fulfilled, (state, action) => {
                state.deleteLoading = false;
            })
            .addCase(deleteUserSubscription.rejected, (state, action) => {
                state.deleteLoading = false;
            });
    },
});

// export const { loginUser, logoutUser } = userSlice.actions;
export default userSubscriptionslice.reducer;
