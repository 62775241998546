import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./userInformation.scss";

import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { MdRemoveRedEye } from "react-icons/md";
import { AppImages } from "../../../Images/AppImages";
import DeleteModel from "../../common/DeleteModel";
import Loader from "../../common/Loader";
import Searchbar from "../../common/SearchBar";

 

import {
    deleteContactUsUserData,
    getContactUsUserData,
} from "../../../store/contactUsSlice";
import ContactUsUserDetailModel from "../Models/ContactUsUserDetailModel";

export default function UserInformation() {
  const [openUserDetailModel, setOpenUserDetailModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [usersUpdated, setUsersUpdated] = useState(false);

  const [deleteSelectedUser, setDeleteSelectedUser] = useState(null);
  const [selectdUserData, setselectdUserData] = useState(null);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { deleteLoading, contactUsUserData, contactUsUserDataLoading } =
    useSelector((state) => state.contactUsReducer);

  const deleteUserEntry = () => {
    dispatch(deleteContactUsUserData(deleteSelectedUser._id)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("User Deleted succesfully !");
        setUsersUpdated((prev) => !prev);
        setOpenDeleteModel(false);
        setDeleteSelectedUser(null);
        return;
        }
        console.log(deleteSelectedUser);
      toast.error("Something went wrong, please try again !");
      setOpenDeleteModel(false);
      setDeleteSelectedUser(null);
    });
  };

  useEffect(() => {
    const filterData = tableData?.filter(
      ({ name, email, message }) =>
        name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        message.toLowerCase().includes(searchQuery.toLowerCase()) ||
        email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilterTableData(filterData);
  }, [tableData, searchQuery]);

  useEffect(() => {
    dispatch(getContactUsUserData());
  }, [usersUpdated]);
 

  useEffect(() => {
    setTableData(contactUsUserData);
    setFilterTableData(contactUsUserData);
    setSearchQuery("");
  }, [contactUsUserData]);

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: (row) => row?.name,
        sortable: true,
      },

      {
        name: "Email",
        selector: (row) => row?.email,
        sortable: true,
      },
      {
        name: "Message",
        selector: (row) => row?.message,
        cell: (row) => (
          <span className="ellipsis-msg ">
            {row?.message}
           
          </span>
        ),
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <div className="viewDelete">
            <button
              className="viewBtn"
              onClick={() => {
                setselectdUserData(row);
                setOpenUserDetailModel(true);
              }}
            >
              <MdRemoveRedEye size={20} />
            </button>
            <button
              onClick={() => {
                setDeleteSelectedUser(row);
                setOpenDeleteModel(true);
              }}
              className="deleteBtn"
            >
              <img src={AppImages.deleteBin} />
            </button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    [tableData]
  );

  return (
    <>
      <div className="userDetailsSection">
        <div className="head">
          <p className="section_title">All Users Entries</p>
          <div>
            <Searchbar value={searchQuery} onchange={setSearchQuery} />
          </div>
        </div>
        <div className="table">
          {contactUsUserDataLoading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : tableData?.length ? (
            <DataTable columns={columns} data={filterTableData} pagination />
          ) : (
            <div className="data-notfound">Data not Found</div>
          )}
        </div>
      </div>
      {openUserDetailModel && (
        <ContactUsUserDetailModel
          userData={selectdUserData}
          closeModel={() => setOpenUserDetailModel(false)}
        />
      )}
      {openDeleteModel && (
        <DeleteModel
          message={
            <>
              Are you sure you want to delete{" "}
              <b>'{deleteSelectedUser?.name}'</b> User?
            </>
          }
          loading={deleteLoading}
          deleteCallback={deleteUserEntry}
          closeModel={() => setOpenDeleteModel(false)}
        />
      )}
    </>
  );
}
