import React from "react";
import CustomInput from "../../common/CustomInput";
import "./pageDescription.scss";
 

const PageDescription = ({ handleChange, data, errors }) => {
 

 

  return (
    <div className="main_section_page_Description">
      <p className="section_title">Page Descripion Section</p>
      <div className="titleAndDescription">
        <div className="width_50">
          <CustomInput
            label="Title"
            name="tips_title"
            isCompulsory={true}
            handleChange={handleChange}
            value={data.tips_title}
            error={errors.tips_title}
          />
        </div>

        <div className="width_50">
          <CustomInput
            label="Description"
            isCompulsory={true}
            handleChange={handleChange}
            name="tips_discription"
            value={data.tips_discription}
            error={errors.tips_discription}
          />
        </div>
        <div className="width_50">
          <CustomInput
            label="Video Link"
            isCompulsory={true}
            handleChange={handleChange}
            name="tips_link"
            value={data.tips_link}
            error={errors.tips_link}
          />
        </div>
      </div>
    </div>
  );
};

export default PageDescription;
