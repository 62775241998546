import React, { useEffect, useState } from "react";
import "./popup.scss";
 
import { useDispatch, useSelector } from "react-redux";
 
import toast from "react-hot-toast";
import Button from "../../components/common/Button";
import CustomInput from "../../components/common/CustomInput";
import { getPopupData, sendPopupData } from "../../store/popupSlice";
export default function Popup() {
  const [previewImage, setPreviewImage] = useState(null);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
 
  const {
     popupData,
     loading,
  } = useSelector((state) => state.popupReducer);
  console.log(data);
  useEffect(() => {
    dispatch(getPopupData());
  }, []);

  useEffect(() => {
    setData(popupData);
  }, [popupData]);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => { 
        console.log(reader.result);
        setPreviewImage(reader.result);
        handleChange({ target: { name: "popup_image", value: file } });
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
      handleChange({ target: { name: "popup_image", value: null } });
    }
  };

  const validate = (updatedData = data) => {
    let tempErrors = {};
    if (!updatedData.popup_title?.trim())
      tempErrors.popup_title = "Title is required.";
    if (!updatedData.popup_description?.trim())
      tempErrors.popup_description = "Description is required.";
    else if (updatedData.popup_description?.length > 500)
      tempErrors.popup_description =
        "Maximum 500 characters allowed. Please shorten your text.";
    if (!updatedData.popup_image)
      tempErrors.popup_image = "Image is required.";
    else if (
      typeof updatedData.popup_image === "object" &&
      !updatedData?.popup_image.type.startsWith("image/")
    )
      tempErrors.popup_image = "File is not a valid image type.";
 

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e?.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("popup_description", data.popup_description?.trim());
      formData.append("popup_image", data.popup_image);
      formData.append("popup_title", data.popup_title?.trim());
 
      formData.append("name", data?.name);

      dispatch(sendPopupData(formData)).then((res) => {
    
        if (res.payload.status && res.payload.status === 200) {
          toast.success("Popup updated succesfully !");
        }
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedData = { ...data, [name]: value };

    setData(updatedData);
    validate(updatedData);
  };
  console.log(data);
  console.log(previewImage);
  return (
    <form onSubmit={handleSubmit} className="main_section_popup">
      <p className="section_title">Popup section</p>
      <div className="titleAndDescription">
        <div className="width_50">
          <CustomInput
            label="Title"
            name="popup_title"
            isCompulsory={true}
            handleChange={handleChange}
            value={data?.popup_title}
            error={errors.popup_title}
          />
        </div>

        <div className="width_50">
          <CustomInput
            label="Description"
            isCompulsory={true}
            handleChange={handleChange}
            name="popup_description"
            value={data?.popup_description}
            error={errors.popup_description}
          />
        </div>
        <div className="width_50">
          <CustomInput
            label="Image"
            type="file"
            isCompulsory={true}
            accept="image/*"
            style={{
              padding: "13px",
            }}
            handleChange={handleImageChange}
            name="popup_image"
            error={errors.popup_image}
          />

          {previewImage && previewImage.startsWith("data:") ? (
            data?.popup_image?.type.startsWith("image/") ? (
              <img src={previewImage} alt="Preview" className="preview-image" />
            ) : null
          ) : data?.popup_image ? (
            <img
              src={data?.popup_image}
              alt="Preview"
              className="preview-image"
            />
          ) : null}
        </div>
       
      
      </div>
      <Button type="submit" className="btn" loading={loading}>
        Save
      </Button>
    </form>
  );
}
