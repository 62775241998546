import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROUTES } from "../services/apiRoutes";
import axiosInstance from "../utils/axiosInstance";

const initialState = {
    getAllCreditCardsUsersEntriesLoader:false,
 
    deleteLoading: false,
    allCreditCardsUsersEntriesData: [],
};

export const getAllCreditCardsUsersEntries = createAsyncThunk(
    "admin/getAllCreditCardsUsersEntries",
    async (thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_ALL_CREDITCARD_USERS_ENTRIES);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

export const deleteCreditCardUserEntry = createAsyncThunk(
    "admin/deleteCreditCardUserEntry",
    async (id, thunkAPI) => {
        try {
            const response = await axiosInstance.delete(API_ROUTES.DELETE_CREDIT_CARD_USER_ENTRY+id);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const CreditCardUsersEntriesSlice = createSlice({
    name: "CreditCardUsersEntriesSlice",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAllCreditCardsUsersEntries.pending, (state, action) => {
                state.getAllCreditCardsUsersEntriesLoader = true;
            })
            .addCase(getAllCreditCardsUsersEntries.fulfilled, (state, action) => {
                state.getAllCreditCardsUsersEntriesLoader = false;
                state.allCreditCardsUsersEntriesData = action.payload.data.data;
            })
            .addCase(getAllCreditCardsUsersEntries.rejected, (state, action) => {
                state.getAllCreditCardsUsersEntriesLoader = false;
            });

        builder
            .addCase(deleteCreditCardUserEntry.pending, (state, action) => {
                state.deleteLoading = true;
            })
            .addCase(deleteCreditCardUserEntry.fulfilled, (state, action) => {
                state.deleteLoading = false;
            })
            .addCase(deleteCreditCardUserEntry.rejected, (state, action) => {
                state.deleteLoading = false;
            });
    },
});

// export const { loginUser, logoutUser } = userSlice.actions;
export default CreditCardUsersEntriesSlice.reducer;
