import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/common/Button";
import CustomInput from "../../components/common/CustomInput";
import "./footer.scss";
import { getFooterData, sendFooterData } from "../../store/footerSlice";

const Footer = () => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const { footerData, loading } = useSelector((state) => state.footerReducer);
  useEffect(() => {
    dispatch(getFooterData());
  }, []);

  useEffect(() => {
    setData(footerData);
  }, [footerData]);
  const validate = (updatedData = data) => {
    let tempErrors = {};

    if (!updatedData?.footer_description?.trim())
      tempErrors.footer_description = "Description is required.";

    if (!updatedData?.instagram_link?.trim())
      tempErrors.instagram_link = "Instagram link is required.";
    if (!updatedData?.facebook_link?.trim())
      tempErrors.facebook_link = "Facebook link is required.";
    if (!updatedData.twitter_link?.trim())
      tempErrors.twitter_link = "Twitter link is required.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e?.preventDefault();
    if (validate()) {
      dispatch(
        sendFooterData({
          ...data,
          instagram_link: data?.instagram_link?.trim(),
          facebook_link: data?.facebook_link?.trim(),
          footer_description: data?.footer_description?.trim(),
          twitter_link: data?.twitter_link?.trim(),
        })
      ).then((res) => {
        if (res.payload.status && res.payload.status === 200) {
          toast.success("Footer updated succesfully !");
        }
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedData = { ...data, [name]: value };

    setData(updatedData);
    validate(updatedData);
  };

  return (
    <form onSubmit={handleSubmit} className="main_section_footer">
      <p className="section_title">Footer Section</p>
      <div className="titleAndDescription">
        <div className="width_50">
          <CustomInput
            label="Description"
            isCompulsory={true}
            name="footer_description"
            handleChange={handleChange}
            value={data?.footer_description}
            error={errors?.footer_description}
          />
        </div>
        <div className="width_50">
          <CustomInput
            label="Facebook Link"
            isCompulsory={true}
            name="facebook_link"
            handleChange={handleChange}
            value={data?.facebook_link}
            error={errors?.facebook_link}
          />
        </div>
        <div className="width_50">
          <CustomInput
            label="Instagram Link"
            isCompulsory={true}
            name="instagram_link"
            handleChange={handleChange}
            value={data?.instagram_link}
            error={errors?.instagram_link}
          />
        </div>
        <div className="width_50">
          <CustomInput
            label="Twitter(X) Link"
            isCompulsory={true}
            name="twitter_link"
            handleChange={handleChange}
            value={data?.twitter_link}
            error={errors?.twitter_link}
          />
        </div>
      </div>
      <Button type="submit" className="btn" loading={loading}>
        Save
      </Button>
    </form>
  );
};

export default Footer;
