import React, { useEffect, useMemo, useState } from "react";

import "./userSubscription.scss";

import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppImages } from "../../../Images/AppImages";
import {
  deleteUserSubscription,
  getAllUserSubscriptionData,
} from "../../../store/userSubscriptionslice";
import DeleteModel from "../../common/DeleteModel";
import DropDownIdValue from "../../common/DropDownIdValue";
import Loader from "../../common/Loader";
import Searchbar from "../../common/SearchBar";
import { MdContentCopy } from "react-icons/md";
import { copyToClipboard } from "../../../utils/helper";
const subscriptionTypes = [
  { _id: "news_letter", name: "News letter" },
  { _id: "car_loan", name: "Car loan" },
  { _id: "apartments", name: "Apartments" },
  { _id: "All subscriber", name: "All subscriber" },
];

export default function UserSubScriptions() {
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [userSubscriptionsDataUpdated, setUserSubscriptionsDataUpdated] =
    useState(false);

  const [deleteSelectedUserSubscription, setDeleteSelectedUserSubScription] =
    useState(null);

  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [copyData, setCopyData] = useState("");
  const {
    getAllUserSubscriptionDataLoading,
    allUserSubscriptionData,
    deleteLoading,
  } = useSelector((state) => state.userSubscriptionsReducer);

  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState({
    value: "All subscriber",
    label: "All subscriber",
  });
  const deleteUserSubscriptions = () => {
    dispatch(deleteUserSubscription(deleteSelectedUserSubscription._id)).then(
      (res) => {
        if (res?.payload?.status === 200) {
          toast.success("Credit Card Deleted succesfully !");
          setUserSubscriptionsDataUpdated((prev) => !prev);
          setOpenDeleteModel(false);
          setDeleteSelectedUserSubScription(null);
          return;
        }
        toast.error("Something went wrong, please try again !");
        setOpenDeleteModel(false);
        setDeleteSelectedUserSubScription(null);
      }
    );
  };
  useEffect(() => {
    dispatch(getAllUserSubscriptionData());
  }, [userSubscriptionsDataUpdated]);

  useEffect(() => {
    setTableData(allUserSubscriptionData);
    setFilterTableData(allUserSubscriptionData);
    setSearchQuery("");
  }, [allUserSubscriptionData]);

  const columns = useMemo(
    () => [
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Subscription Type",
        selector: (row) => row?.subscriptionType,
        sortable: true,
      },

      {
        name: "Actions",
        cell: (row) => (
          <div className="editDelete">
            <button
              onClick={() => {
                setDeleteSelectedUserSubScription(row);
                setOpenDeleteModel(true);
              }}
              className="deleteBtn"
            >
              <img src={AppImages.deleteBin} />
            </button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    [tableData]
  );
  useEffect(() => {
    let filterData = [...tableData];

    const searched = filterData?.filter(
      ({ email, subscriptionType }) =>
        subscriptionType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        email?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    filterData = searched;

    if (selectedSubscriptionType?.value === "news_letter") {
      filterData = filterData?.filter(
        ({ subscriptionType }) => subscriptionType === "news_letter"
      );
    } else if (selectedSubscriptionType?.value === "car_loan") {
      filterData = filterData?.filter(
        ({ subscriptionType }) => subscriptionType === "car_loan"
      );
    } else if (selectedSubscriptionType?.value === "apartments") {
      filterData = filterData?.filter(
        ({ subscriptionType }) => subscriptionType === "apartments"
      );
    }
    setCopyData(filterData.map(({ email }) => email).join(", "));
    setFilterTableData(filterData);
  }, [
    searchQuery,
    selectedSubscriptionType,
    selectedSubscriptionType?.value,
    tableData,
  ]);

  return (
    <>
      <div className="creditCardsSection">
        <div className="head">
          <p className="section_title">Manage User SubScription</p>
          <button
            className="copy-btn"
            title="Click to copy Emails of all filtered subscribers."
            onClick={() =>
              copyToClipboard(
                copyData,
                `Emails of all ${
                  selectedSubscriptionType?.label === "All subscriber"
                    ? ""
                    : selectedSubscriptionType?.label || ""
                     
                } subscribers are Copied to clipboard`
              )
            }
          >
            <MdContentCopy size={21} />
          </button>
        </div>
        <div className="table">
          <div className="table-title-search">
            <div className="dropdwon">
            <DropDownIdValue
              label={"User Subscription Type"}
            
              options={subscriptionTypes}
              name="category"
              value={
                selectedSubscriptionType
                  ? {
                      value: selectedSubscriptionType?.value,
                      label: selectedSubscriptionType?.label,
                    }
                  : null
              }
              handleChange={(e) => {
                setSelectedSubscriptionType(e.target.value);
              }}
            />

            </div>
           
            <div>
              <Searchbar value={searchQuery} onchange={setSearchQuery} />
            </div>
          </div>
          {getAllUserSubscriptionDataLoading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : tableData?.length ? (
            <DataTable columns={columns} data={filterTableData} pagination />
          ) : (
            <div className="data-notfound">Data not Found</div>
          )}
        </div>
      </div>

      {openDeleteModel && (
        <DeleteModel
          message={
            <>
              Are you sure you want to delete{" "}
              <b>'{deleteSelectedUserSubscription?.email}'</b> ?
            </>
          }
          loading={deleteLoading}
          deleteCallback={deleteUserSubscriptions}
          closeModel={() => setOpenDeleteModel(false)}
        />
      )}
    </>
  );
}
