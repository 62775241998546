import React, { useState } from "react";
 
import { motion } from "framer-motion";
import "./sidebar.scss";
import { IoLogOut } from "react-icons/io5";
import { BiSolidDashboard } from "react-icons/bi";
import { RiCompassDiscoverFill } from "react-icons/ri";
import { SiPopos } from "react-icons/si";
import { FaUsers } from "react-icons/fa";
import { FaIdCard } from "react-icons/fa6";
import { FaCompass } from "react-icons/fa";
import { RiContactsBook3Fill } from "react-icons/ri";
// import { TiContacts } from "react-icons/ti";
import { RiLayoutBottom2Fill } from "react-icons/ri";
import { BsFillCreditCard2BackFill } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdUnsubscribe } from "react-icons/md";
import DeleteModel from "../common/DeleteModel";
import appLogo from "../../Images/applogo.svg"
const SideBar = () => {
  const navigate = useNavigate();
  const [logOutModel, setLogoutModel
    
  ] = useState(false);
  const location = useLocation();
  const navRoutes = [
    {
      path: "/home",
      name: "Home",
      icon: <BiSolidDashboard size={24} />,
    },
    {
      path: "/creditcard",
      name: "Credit Card",
      icon: <BsFillCreditCard2BackFill size={22} />,
    },
    {
      path: "/creditcard-user-entries",
      name: "User Entries",
      icon: <FaUsers size={22} />,
    },
    {
      path: "/user-subscription",
      name: "User Subscription",
      icon: <MdUnsubscribe size={26} />,
    },
    {
      path: "/user-about-us",
      name: "About Us",
      icon: <FaIdCard size={26} />,
    },
    {
      path: "/footer",
      name: "Footer",
      icon: <RiLayoutBottom2Fill size={26} />,
    },
    {
      path: "/discover",
      name: "Discover",
      icon: <RiCompassDiscoverFill size={26} />,
    },
    {
      path: "/popup",
      name: "Popup",
      icon: <SiPopos size={26} />,
    },
    {
      path: "/guides",
      name: "Guides",
      icon: <FaCompass size={26} />,
    },
    {
      path: "/contact-us",
      name: "Contact Us",
      icon: <RiContactsBook3Fill size={26} />,
    },
  ];
  return (
    <>
      <div className="admin-sidebar-wrapper">
        <motion.div className="sidebar-container">
          <div className="sidebar-details">
            <div className="logo_scott">
                <Link to="/home"><img   src={appLogo} alt="" /></Link>
            </div>
            {navRoutes.map((item, index) => {
              return (
                <div
                  className={`sidebar-item ${
                    location.pathname === item.path &&
                    "sidebar-item sidebar-item-selected "
                  } `}
                  key={item.name}
                  onClick={() => {
                    navigate(item.path);
                  }}
                >
                  <div
                    className={`sidebar-icon ${
                      location.pathname === item.path && "active-color"
                    }`}
                    onClick={() => {
                      navigate(item.path);
                    }}
                  >
                    {item.icon}
                    {/* <img src={item.icon} alt="" /> */}
                  </div>
                  <div
                    className={`sidebar-name ${
                      location.pathname === item.path && "active-color"
                    }`}
                  >
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="sidebar-footer"
            onClick={() => {
              setLogoutModel(true);
            }}
          >
            <div className="sidebar-footer-image logoutOption">
              <IoLogOut size={24} />
            </div>
            <div className="sidebar-footer-name">
              <div className="sidebar-name logoutOption">Logout</div>
            </div>
          </div>
        </motion.div>
      </div>
      {logOutModel && (
        <DeleteModel
          buttonName={"Logout"}
          closeModel={() => setLogoutModel(false)}
          deleteCallback={() => {
            setLogoutModel(false);
            window.location.href = "/";
            localStorage.clear();
          }}
          message={
            <>
              Are you sure you want <b>'logout'</b>
            </>
          }
        />
      )}
    </>
  );
};

export default SideBar;

// the below code fragment can be found in:
