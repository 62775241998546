import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROUTES } from "../services/apiRoutes";
import axiosInstance from "../utils/axiosInstance";

const initialState = {
    loading: false,
    aboutUsData: {},
};

export const getAboutUsPageData = createAsyncThunk(
    "admin/getAboutUsPageData",
    async (thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_ABOUT_US_PAGE_DATA);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

export const SendAboutUsPageData = createAsyncThunk(
    "admin/SendAboutUsPageData",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.put(API_ROUTES.PUT_ABOUT_US_PAGE_DATA, data);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const AboutUsPageSlice = createSlice({
    name: "AboutUsPageSlice",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAboutUsPageData.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAboutUsPageData.fulfilled, (state, action) => {
                state.loading = false;
                state.aboutUsData = action.payload.data.data;
            })
            .addCase(getAboutUsPageData.rejected, (state, action) => {
                state.loading = false;
            });

        builder
            .addCase(SendAboutUsPageData.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(SendAboutUsPageData.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(SendAboutUsPageData.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

// export const { loginUser, logoutUser } = userSlice.actions;
export default AboutUsPageSlice.reducer;
