import React, { useEffect, useRef, useState } from "react";
import "./addEditModel.scss";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppImages } from "../../../../Images/AppImages";
import { addBenefit, editBenefit } from "../../../../store/discoverSlice";
import Button from "../../../common/Button";
import CustomInput from "../../../common/CustomInput";

export default function AddEditBenefitModel({
  editBenefitData,
  setBenefitUpdated,
  closeModel,
}) {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

 
  const [data, setData] = useState({});

  const { addEditBenifitLoading } = useSelector(
    (state) => state.discoverReducer
  );
  console.log(editBenefitData);
  useEffect(() => {
    if (!editBenefitData) return;
    setData({
      ...editBenefitData,
    });
  }, []);

  const validate = (updatedData = data) => {
    let tempErrors = {};
    if (!updatedData.banefit_title?.trim())
      tempErrors.banefit_title = "Benefit title is required.";
    if (!updatedData.banefit_description?.trim())
      tempErrors.banefit_description = "Benefit description is required.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
 

    if (validate()) {
      if (editBenefitData) {
        dispatch(
          editBenefit({
            ...data,
            banefit_description: data.banefit_description?.trim(),
            banefit_title: data.banefit_title?.trim(),
          })
        ).then((res) => {
          if (res.payload.status && res.payload.status === 200) {
            toast.success("Benefit  updated succesfully !");
            setBenefitUpdated((prev) => !prev);
            closeModel?.();
          }
        });
      } else {
        dispatch(
          addBenefit({
            ...data,
            banefit_description: data.banefit_description?.trim(),
            banefit_title: data.banefit_title?.trim(),
          })
        ).then((res) => {
          if (res.payload.status && res.payload.status === 200) {
            setBenefitUpdated((prev) => !prev);

            toast.success("Benefit updated succesfully !");
            closeModel?.();
          }
        });
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedData = {};

    updatedData = { ...data, [name]: value };

    setData(updatedData);
    validate(updatedData);
  };

  const handleClickOutside = (event) => {
    if (
      !addEditBenifitLoading &&
      formRef.current &&
      !formRef.current.contains(event.target)
    ) {
      closeModel();
    }
  };

  console.log(data?.category);
  return (
    <div className="AddEditBenefitModel" onClick={handleClickOutside}>
      <div className="wrapper">
        <form ref={formRef} onSubmit={handleSubmit} className="credit_card">
          <p className="section_title">
            {editBenefitData ? "Edit" : "Add "} Benefit{" "}
          </p>
          <div className="titleAndDescription">
            <div className="width_100">
              <CustomInput
                label="Benefit Title"
                isCompulsory={true}
                handleChange={handleChange}
                name="banefit_title"
                value={data?.banefit_title}
                error={errors.banefit_title}
              />
            </div>
            <div className="width_100">
              <CustomInput
                label="Benefit description"
                isCompulsory={true}
                handleChange={handleChange}
                name="banefit_description"
                value={data?.banefit_description}
                error={errors.banefit_description}
              />
            </div>
          </div>
          <Button type="submit" className="btn" loading={addEditBenifitLoading}>
            {editBenefitData ? "Save" : "Add"}
          </Button>
        </form>

        <button onClick={() => closeModel?.()} className="cross">
          <img src={AppImages.cross} alt="" />
        </button>
      </div>
    </div>
  );
}
