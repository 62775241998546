import React, { useRef } from "react";
import "./deleteModel.scss";
import Button from "../Button";
import { AppImages } from "../../../Images/AppImages";
export default function DeleteModel({
  message = "Are you sure you want to delete this?",
  deleteCallback,
  loading,
  closeModel,
  buttonName="Delete"
}) {
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (!loading && ref.current && !ref.current.contains(event.target)) {
      closeModel?.();
    }
  };
  return (
    <div className="deleteModel" onClick={handleClickOutside}>
      <div ref={ref} className="delete-container">
        <img src={AppImages.alert} alt="alert" />
        <div className="message">{message}</div>

        <div className="btn-container">
          <Button
            className=" btn deleteBtn"
            loading={loading}
            onClick={deleteCallback}
          >
            {buttonName}
          </Button>
          <Button
            onClick={closeModel}
            className="btn cancelBtn "
            disabled={loading}
          >
            Cancel
          </Button>
        </div>
        <button onClick={() => closeModel()} className="cross">
          <img src={AppImages.cross} alt="" />
        </button>
      </div>
    </div>
  );
}
