import store from "./store";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./App.css";
import AppRoutes from "./routes";
import "./styles/globals.scss";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AppRoutes />
      </Provider>
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
