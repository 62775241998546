import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROUTES } from "../services/apiRoutes";
import axiosInstance from "../utils/axiosInstance";

const initialState = {
    loading: false,
    creditCardBannerDataLoading: false,
    getCreditCardSubCategoryByCategoryIdLoading: false,
    editCreditCardSubCategoryLoading: false,
    addCreditCardSubCategoryLoading: false,
    addSubCategoryLoading:false,
    addEditCreditCardLoading: false,
    addEditCategoryLoading: false,
    getallCreditCardsLoading: false,
    getCategoryLoading: false,
    deleteLoading: false,
    creditCardData: {},
};

// _____________________________________CreditCardBannerData_Start________________________________________________

export const getCreditCardBannerData = createAsyncThunk(
    "admin/getCreditCardBannerData",
    async (thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_CREDIT_CARD_BANNER);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

export const sendCreditCardBannerData = createAsyncThunk(
    "admin/sendCreditCardBannerData",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.put(API_ROUTES.PUT_CREDIT_CARD_BANNER, data);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
// _____________________________________CreditCardBannerData_END________________________________________________

// _____________________________________Categories_START ________________________________________________

export const getCreditCardCategories = createAsyncThunk(
    "admin/getCreditCardCategories",
    async (thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_CREDIT_CARD_CATEGORIES);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

export const addNewCreditCardCategory = createAsyncThunk(
    "admin/addNewCreditCardCategory",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.post(API_ROUTES.POST_CREDIT_CARD_CATEGORY, data);
            return response;
        } catch (error) {
            console.log(error, "thunk")
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const addNewCreditCardSubCategory = createAsyncThunk(
    "admin/addNewCreditCardSubCategory",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.post(API_ROUTES.POST_CREDIT_CARD_SUB_CATEGORY, data);
            return response;
        } catch (error) {
            console.log(error, "thunk")
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const editCreditCardCategory = createAsyncThunk(
    "admin/editCreditCardCategory",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.put(API_ROUTES.PUT_CREDIT_CARD_CATEGORY + data?.id, { name: data?.name });
            return response;
        } catch (error) {
            console.log(error, "thunk")
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const editCreditCardSubCategory= createAsyncThunk(
    "admin/editCreditCardSubCategory",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.put(API_ROUTES.PUT_CREDIT_CARD_SUB_CATEGORY + data?.id,data.data  );
            return response;
        } catch (error) {
            console.log(error, "thunk")
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const deleteCreditCardCategory = createAsyncThunk(
    "admin/deleteCreditCardCategory",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.delete(API_ROUTES.DELETE_CREDIT_CARD_CATEGORY + data,);
            return response;
        } catch (error) {
            console.log(error, "thunk")
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const deleteCreditCardSubCategory = createAsyncThunk(
    "admin/deleteCreditCardSubCategory",
    async (id, thunkAPI) => {
        try {
            const response = await axiosInstance.delete(API_ROUTES.DELETE_CREDIT_CARD_SUB_CATEGORY + id);
            return response;
        } catch (error) {
            console.log(error, "thunk")
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const getCreditCardSubCategoryByCategoryId = createAsyncThunk(
    "admin/getCreditCardSubCategoryByCategoryId",
    async (id, thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_CREDIT_CARD_SUB_CATEGORIES_BY_CATEGORY_ID + id);
            return response;
        } catch (error) {
            console.log(error, "thunk")
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

// _____________________________________Categories_END __________________________________________________

//_______________________________________Credit_Card_STRAT_________________________________________-
export const addNewCreditCard = createAsyncThunk(
    "admin/addNewCreditCard",
    async (data, thunkAPI) => {
        try {
            console.log(data)
            const response = await axiosInstance.post(API_ROUTES.POST_CREDIT_CARD, data);
            return response;
        } catch (error) {
            console.log(error, "thunk")
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const editCreditCardData = createAsyncThunk(
    "admin/editCreditCardData",
    async ({ id, formData }, thunkAPI) => {
        try {
            // console.log(data)
            const response = await axiosInstance.put(API_ROUTES.PUT_CREDIT_CARD + id, formData);
            return response;
        } catch (error) {
            console.log(error, "thunk")
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const deleteCreditCardData = createAsyncThunk(
    "admin/deleteCreditCardData",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.delete(API_ROUTES.DELETE_CREDIT_CARD + data);
            return response;
        } catch (error) {
            console.log(error, "thunk")
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);



// export const getCreditCardData = createAsyncThunk(
//     "admin/getCreditCardData",
//     async (data, thunkAPI) => {
//         try {
//             const response = await axiosInstance.get(API_ROUTES.PUT_CREDIT_CARD + data);
//             return response;
//         } catch (error) {
//             console.log(error, "thunk")
//             return thunkAPI.rejectWithValue(error.response); // Reject with an error message
//         }
//     }
// );
export const getAllCreditCardsData = createAsyncThunk(
    "admin/getAllCreditCardsData",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_ALL_CREDIT_CARDS);
            return response;
        } catch (error) {
            console.log(error, "thunk")
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);






//_______________________________________Credit_Card_END_________________________________________-

export const CreditCardPageSlice = createSlice({
    name: "CreditCardPageSlice",
    initialState,
    reducers: {},
    extraReducers(builder) {
        // credit card banner
        builder
            .addCase(getCreditCardBannerData.pending, (state, action) => {
                state.creditCardBannerDataLoading = true;
            })
            .addCase(getCreditCardBannerData.fulfilled, (state, action) => {
                state.creditCardBannerDataLoading = false;
                state.creditCardData.creditCardBannerData = action.payload.data.data;
            })
            .addCase(getCreditCardBannerData.rejected, (state, action) => {
                state.creditCardBannerDataLoading = false;
            });

        builder
            .addCase(sendCreditCardBannerData.pending, (state, action) => {
                state.creditCardBannerDataLoading = true;
            })
            .addCase(sendCreditCardBannerData.fulfilled, (state, action) => {
                state.creditCardBannerDataLoading = false;
            })
            .addCase(sendCreditCardBannerData.rejected, (state, action) => {
                state.creditCardBannerDataLoading = false;
            });

        //category
        builder
            .addCase(getCreditCardCategories.pending, (state, action) => {
                state.getCategoryLoading = true;
            })
            .addCase(getCreditCardCategories.fulfilled, (state, action) => {
                state.getCategoryLoading = false;
                state.creditCardData.creditCardCategories = action.payload.data.data;
            })
            .addCase(getCreditCardCategories.rejected, (state, action) => {
                state.getCategoryLoading = false;
            });

        builder
            .addCase(addNewCreditCardCategory.pending, (state, action) => {
                state.addEditCategoryLoading = true;
            })
            .addCase(addNewCreditCardCategory.fulfilled, (state, action) => {
                state.addEditCategoryLoading = false;
            })
            .addCase(addNewCreditCardCategory.rejected, (state, action) => {
                state.addEditCategoryLoading = false;
            });
        builder
            .addCase(addNewCreditCardSubCategory.pending, (state, action) => {
                state.addSubCategoryLoading = true;
            })
            .addCase(addNewCreditCardSubCategory.fulfilled, (state, action) => {
                state.addSubCategoryLoading = false;
            })
            .addCase(addNewCreditCardSubCategory.rejected, (state, action) => {
                state.addSubCategoryLoading = false;
            });
        builder
            .addCase(editCreditCardCategory.pending, (state, action) => {
                state.addEditCategoryLoading = true;
            })
            .addCase(editCreditCardCategory.fulfilled, (state, action) => {
                state.addEditCategoryLoading = false;
            })
            .addCase(editCreditCardCategory.rejected, (state, action) => {
                state.addEditCategoryLoading = false;
            });
        builder
            .addCase(deleteCreditCardCategory.pending, (state, action) => {
                state.deleteLoading = true;
            })
            .addCase(deleteCreditCardCategory.fulfilled, (state, action) => {
                state.deleteLoading = false;
            })
            .addCase(deleteCreditCardCategory.rejected, (state, action) => {
                state.deleteLoading = false;
            });
        builder
            .addCase(deleteCreditCardSubCategory.pending, (state, action) => {
                state.deleteLoading = true;
            })
            .addCase(deleteCreditCardSubCategory.fulfilled, (state, action) => {
                state.deleteLoading = false;
            })
            .addCase(deleteCreditCardSubCategory.rejected, (state, action) => {
                state.deleteLoading = false;
            });

        // credit card
        builder
            .addCase(addNewCreditCard.pending, (state, action) => {
                state.addEditCreditCardLoading = true;
            })
            .addCase(addNewCreditCard.fulfilled, (state, action) => {
                state.addEditCreditCardLoading = false;
                // state.creditCardData.creditCard = action.payload.data.data;
            })
            .addCase(addNewCreditCard.rejected, (state, action) => {
                state.addEditCreditCardLoading = false;
            });

        builder
            .addCase(editCreditCardData.pending, (state, action) => {
                state.addEditCreditCardLoading = true;
            })
            .addCase(editCreditCardData.fulfilled, (state, action) => {
                state.addEditCreditCardLoading = false;
                // state.creditCardData.creditCard = action.payload.data.data;
            })
            .addCase(editCreditCardData.rejected, (state, action) => {
                state.addEditCreditCardLoading = false;
            });
        builder
            .addCase(getCreditCardSubCategoryByCategoryId.pending, (state, action) => {
                state.getCreditCardSubCategoryByCategoryIdLoading = true;
            })
            .addCase(getCreditCardSubCategoryByCategoryId.fulfilled, (state, action) => {
                state.getCreditCardSubCategoryByCategoryIdLoading = false;
                state.creditCardData.CreditCardSubCategoriesOfCategory = action.payload.data.data;
            })
            .addCase(getCreditCardSubCategoryByCategoryId.rejected, (state, action) => {
                state.getCreditCardSubCategoryByCategoryIdLoading = false;
            });
        builder
            .addCase(editCreditCardSubCategory.pending, (state, action) => {
                state.editCreditCardSubCategoryLoading = true;
            })
            .addCase(editCreditCardSubCategory.fulfilled, (state, action) => {
                state.editCreditCardSubCategoryLoading = false;
     
            })
            .addCase(editCreditCardSubCategory.rejected, (state, action) => {
                state.editCreditCardSubCategoryLoading = false;
            });

        // builder
        //     .addCase(getCreditCardData.pending, (state, action) => {
        //         state.addEditCreditCardLoading = true;
        //     })
        //     .addCase(getCreditCardData.fulfilled, (state, action) => {
        //         state.addEditCreditCardLoading = false;
        //         state.creditCardData.creditCard = action.payload.data.data;
        //     })
        //     .addCase(getCreditCardData.rejected, (state, action) => {
        //         state.addEditCreditCardLoading = false;
        //     });

        builder
            .addCase(getAllCreditCardsData.pending, (state, action) => {
                state.getallCreditCardsLoading = true;
            })
            .addCase(getAllCreditCardsData.fulfilled, (state, action) => {
                state.getallCreditCardsLoading = false;
                state.creditCardData.allCreditCards = action.payload.data.data;
            })
            .addCase(getAllCreditCardsData.rejected, (state, action) => {
                state.getallCreditCardsLoading = false;
            });
        builder
            .addCase(deleteCreditCardData.pending, (state, action) => {
                state.deleteLoading = true;
            })
            .addCase(deleteCreditCardData.fulfilled, (state, action) => {
                state.deleteLoading = false;
                state.creditCardData.allCreditCards = action.payload.data.data;
            })
            .addCase(deleteCreditCardData.rejected, (state, action) => {
                state.deleteLoading = false;
            });

    },
});

// export const { loginUser, logoutUser } = userSlice.actions;
export default CreditCardPageSlice.reducer;
