import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROUTES } from "../services/apiRoutes";
import axiosInstance from "../utils/axiosInstance";

const initialState = {
    contactUsMapLinkDataLoading: false,
    contactUsMapLinkData: {},
    contactUsUserData: [],
    contactUsUserDataLoading: false,
    deleteLoading:false

};

export const getContactUsMapLinkData = createAsyncThunk(
    "admin/getContactUsMapLinkData",
    async (thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_CONTACT_US_MAP_LINK_DATA);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

export const SendcontactUsMapLinkDataData = createAsyncThunk(
    "admin/SendcontactUsMapLinkDataData",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.put(API_ROUTES.PUT_CONTACT_US_MAP_LINK_DATA, data);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

// conatct us User Data

export const getContactUsUserData = createAsyncThunk(
    "admin/getContactUsUserData",
    async (thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_CONTACT_US_DATA);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

export const deleteContactUsUserData = createAsyncThunk(
    "admin/deleteContactUsUserData",
    async (id, thunkAPI) => {
        try {
            const response = await axiosInstance.delete(API_ROUTES.DELETE_CONTACT_US_DATA+id);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const ContactUsSlice = createSlice({
    name: "ContactUsSlice",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getContactUsMapLinkData.pending, (state, action) => {
                state.contactUsMapLinkDataLoading = true;
            })
            .addCase(getContactUsMapLinkData.fulfilled, (state, action) => {
                state.contactUsMapLinkDataLoading = false;
                state.contactUsMapLinkData = action.payload.data.data;
            })
            .addCase(getContactUsMapLinkData.rejected, (state, action) => {
                state.contactUsMapLinkDataLoading = false;
            });

        builder
            .addCase(SendcontactUsMapLinkDataData.pending, (state, action) => {
                state.contactUsMapLinkDataLoading = true;
            })
            .addCase(SendcontactUsMapLinkDataData.fulfilled, (state, action) => {
                state.contactUsMapLinkDataLoading = false;
                
            })
            .addCase(SendcontactUsMapLinkDataData.rejected, (state, action) => {
                state.contactUsMapLinkDataLoading = false;
            });
        
        // contact us user 
        builder
            .addCase(getContactUsUserData.pending, (state, action) => {
                state.contactUsUserDataLoading = true;
            })
            .addCase(getContactUsUserData.fulfilled, (state, action) => {
                state.contactUsUserDataLoading = false;
                state.contactUsUserData = action.payload.data.data;

            })
            .addCase(getContactUsUserData.rejected, (state, action) => {
                state.contactUsUserDataLoading = false;
            });
        builder
            .addCase(deleteContactUsUserData.pending, (state, action) => {
                state.deleteLoading = true;
            })
            .addCase(deleteContactUsUserData.fulfilled, (state, action) => {
                state.deleteLoading = false;
          

            })
            .addCase(deleteContactUsUserData.rejected, (state, action) => {
                state.deleteLoading = false;
            });
    },
});

// export const { loginUser, logoutUser } = userSlice.actions;
export default ContactUsSlice.reducer;
