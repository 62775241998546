import React, { useEffect, useMemo, useState } from "react";
import "./creditCardsSection.scss";

import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppImages } from "../../../Images/AppImages";
import {
  deleteCreditCardData,
  getAllCreditCardsData,
} from "../../../store/creditCardPageSlice";
import Button from "../../common/Button";
import DeleteModel from "../../common/DeleteModel";
import Loader from "../../common/Loader";
import Searchbar from "../../common/SearchBar";
import AddEditCreditCardModel from "../Models/AddEditCreditCardModel";
export default function CreditCardsSection() {
  const [openAddEditCreditCardModel, setOpenAddEditCreditCardModel] =
    useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [creditCardUpdated, setCreditCardUpdated] = useState(false);

  const [deleteSelectedCreditCard, setDeleteSelectedCreditCard] =
    useState(null);
  const [selectdEditCreditCardData, setSelectdEditCreditCardData] =
    useState(null);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    getallCreditCardsLoading,
    deleteLoading,
    creditCardData: { allCreditCards },
  } = useSelector((state) => state.CreditCardPageReducer);

  console.log(allCreditCards);
  const deleteCreditCard = () => {
    dispatch(deleteCreditCardData(deleteSelectedCreditCard._id)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("Credit Card Deleted succesfully !");
        setCreditCardUpdated((prev) => !prev);
        setOpenDeleteModel(false);
        setDeleteSelectedCreditCard(null);
        return;
      }
      toast.error("Something went wrong, please try again !");
      setOpenDeleteModel(false);
      setDeleteSelectedCreditCard(null);
    });
  };
  useEffect(() => {
    dispatch(getAllCreditCardsData());
  }, [creditCardUpdated]);

  useEffect(() => {
    setTableData(allCreditCards);
    setFilterTableData(allCreditCards);
    setSearchQuery("");
  
  }, [allCreditCards]);

  const columns = useMemo(
    () => [
      {
        name: "Image",

        cell: (row) => {
          return (
            <div className="img-container">
              <img src={row.card_image} />
            </div>
          );
        },
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "category",
        selector: (row) => row?.category?.name,
        sortable: true,
      },
      {
        name: "subcategory",
        selector: (row) => row?.subcategory?.name,
        sortable: true,
      },

      {
        name: "Actions",
        cell: (row) => (
          <div className="editDelete">
            <button
              className="editBtn"
              onClick={() => {
                setSelectdEditCreditCardData(row);
                setOpenAddEditCreditCardModel(true);
              }}
            >
              <img src={AppImages.edit} />
            </button>
            <button
              onClick={() => {
                setDeleteSelectedCreditCard(row);
                setOpenDeleteModel(true);
              }}
              className="deleteBtn"
            >
              <img src={AppImages.deleteBin} />
            </button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    [tableData]
  );

  const handleSearchInputChange = (query) => {
    setSearchQuery(query);
    const filtered = tableData?.filter(
      ({ subcategory, category, name }) =>
        subcategory?.name.toLowerCase().includes(query.toLowerCase()) ||
        category.name.toLowerCase().includes(query.toLowerCase()) ||
        name.toLowerCase().includes(query.toLowerCase())
    );
    setFilterTableData(filtered);
  };
  return (
    <>
      <div className="creditCardsSection">
        <div className="head">
          <p className="section_title">Manage Credit Cards</p>

          <Button
            onClick={() => {
              setSelectdEditCreditCardData(null);
              setOpenAddEditCreditCardModel(true);
            }}
            className="addBtn"
          >
            Add Credit Card
          </Button>
        </div>
        <div className="table">
          <div className="table-title-search">
            <div className="name">All Credit Cards</div>

            <Searchbar value={searchQuery} onchange={handleSearchInputChange} />
          </div>

          {getallCreditCardsLoading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : tableData?.length ? (
            <DataTable columns={columns} data={filterTableData} pagination />
          ) : (
            <div className="data-notfound">Data not Found</div>
          )}
        </div>
      </div>
      {openAddEditCreditCardModel && (
        <AddEditCreditCardModel
          setCreditCardUpdated={setCreditCardUpdated}
          editCreditCardData={selectdEditCreditCardData}
          closeModel={() => setOpenAddEditCreditCardModel(false)}
        />
      )}
      {openDeleteModel && (
        <DeleteModel
          message={
            <>
              Are you sure you want to delete{" "}
              <b>'{deleteSelectedCreditCard?.name}</b> Credit Card?
            </>
          }
          loading={deleteLoading}
          deleteCallback={deleteCreditCard}
          closeModel={() => setOpenDeleteModel(false)}
        />
      )}
    </>
  );
}
