import React, { useRef } from "react";
import "./userDetails.scss";

import { AppImages } from "../../../../Images/AppImages";
import CustomInput from "../../../common/CustomInput";
function convertDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${month}/${day}/${year}`;
}
export default function UserDetailModel({
  userData,

  closeModel,
}) {
  const ref = useRef(null);

  const {
    first_name,
    middle_name,
    last_name,
    email,
    address,
    apt_unit,
    city,
    state,
    zipcode,
    SSN_No,
    dob,
    is_banner,
    createdAt,
    updatedAt,
  } = userData;

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      closeModel();
    }
  };

  return (
    <div className="user-Details-model" onClick={handleClickOutside}>
      <div className="wrapper">
        <div ref={ref} className="user-details">
          <p className="section_title">User Detail</p>
          <div className="titleAndDescription">
            <div className="width_100">
              <CustomInput
                label="First name"
                disabled={true}
                value={first_name}
              />
            </div>
            <div className="width_100">
              <CustomInput
                label="Middle Initial"
                disabled={true}
                value={middle_name}
              />
            </div>

            <div className="width_100">
              <CustomInput
                label="Last name"
                disabled={true}
                value={last_name}
              />
            </div>
            <div className="width_100">
              <CustomInput
                label="Email Address"
                disabled={true}
                value={email}
              />
            </div>
            <div className="width_100">
              <CustomInput label="Address" value={address} disabled={true} />
            </div>
            <div className="width_100">
              <CustomInput label="Apt/Unit" value={apt_unit} disabled={true} />
            </div>
            <div className="width_100">
              <CustomInput label="City" value={city} disabled={true} />
            </div>
            <div className="width_100">
              <CustomInput label="State" value={state} disabled={true} />
            </div>
            <div className="width_100">
              <CustomInput
                label="ZIP Code (XXXXX)"
                value={zipcode}
                disabled={true}
              />
            </div>
            <div className="width_100">
              <CustomInput
                label="Social Security Number (XXX-XX-XXXX)"
                value={SSN_No}
                disabled={true}
              />
            </div>
            <div className="width_100">
              <CustomInput
                label="Date of Birth (MM/DD/YYYY)"
                value={convertDate(dob)}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <button onClick={() => closeModel?.()} className="cross">
          <img src={AppImages.cross} alt="" />
        </button>
      </div>
    </div>
  );
}
