import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROUTES } from "../services/apiRoutes";
import axiosInstance from "../utils/axiosInstance";

const initialState = {
  loading: false,
  homeData: {},
};

export const getHomePageData = createAsyncThunk(
  "admin/getHomePageData",
  async (thunkAPI) => {
    try {
      const response = await axiosInstance.get(API_ROUTES.GET_HOME_PAGE);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response); // Reject with an error message
    }
  }
);

export const SendHomePageData = createAsyncThunk(
  "admin/SendHomePageData",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.put(API_ROUTES.PUT_HOME_PAGE, data);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response); // Reject with an error message
    }
  }
);
export const HomePageSlice = createSlice({
  name: "HomePageSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getHomePageData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getHomePageData.fulfilled, (state, action) => {
        state.loading = false;
        state.homeData = action.payload.data.data;
      })
      .addCase(getHomePageData.rejected, (state, action) => {
        state.loading = false;
      });

    builder
      .addCase(SendHomePageData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(SendHomePageData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(SendHomePageData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

// export const { loginUser, logoutUser } = userSlice.actions;
export default HomePageSlice.reducer;
