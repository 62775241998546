import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCreditCardCategory,
  getCreditCardCategories,
} from "../../../store/creditCardPageSlice";
import "./categorySection.scss";

import { AppImages } from "../../../Images/AppImages";
import Button from "../../common/Button";
import DeleteModel from "../../common/DeleteModel";
import Loader from "../../common/Loader";
import Searchbar from "../../common/SearchBar";
import AddCreditCardCategoryModel from "../Models/AddCreditCardCategoryModel";
import EditCreditCardCategoryModel from "../Models/EditCreditCardCategoryModel";
export default function CategorySection() {
  const [selectedCategoryForEdit, setSlectedCategoryForEdit] = useState(null);
  const dispatch = useDispatch();
  const [openAddCategoryModel, setOpenAddCategoryModel] = useState(false);
  const [openEditCreditCardCategoryModel, setopenEditCreditCardCategoryModel] =
    useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [data, setData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);

  const {
    getCategoryLoading,
    deleteLoading,
    creditCardData: { creditCardCategories },
  } = useSelector((state) => state.CreditCardPageReducer);
  const [categoryUpdated, setCategoriesUpdated] = useState(true);
  const [deleteSelectedCategory, setDeleteSelectedCategory] = useState(null);
  const [seacrhQuery, setSeacrhQuery] = useState("");

  const deleteCategory = () => {
    dispatch(deleteCreditCardCategory(deleteSelectedCategory?._id)).then(
      (res) => {
        if (res?.payload?.status === 200) {
          toast.success("category Deleted succesfully !");
          setCategoriesUpdated((prev) => !prev);
          setOpenDeleteModel(false);
          setDeleteSelectedCategory(null);
          return;
        }
        toast.error("Something went wrong, please try again !");
        setOpenDeleteModel(false);
      }
    );
  };
  useEffect(() => {
    dispatch(getCreditCardCategories());
  }, [categoryUpdated]);

  useEffect(() => {
    setTableData(creditCardCategories);
    setFilterTableData(creditCardCategories);
    setSeacrhQuery("");
  }, [creditCardCategories]);

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },

      {
        name: "Actions",
        cell: (row) => (
          <div className="editDelete">
            <button
              title="Edit category"
              className="editBtn"
              onClick={() => {
                setSlectedCategoryForEdit(row);
                setopenEditCreditCardCategoryModel(true);
              }}
            >
              <img src={AppImages.edit} />
            </button>
            <button
              title="Delete category"
              onClick={() => {
                setDeleteSelectedCategory(row);
                setOpenDeleteModel(true);
              }}
              className="deleteBtn"
            >
              <img src={AppImages.deleteBin} />
            </button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    [tableData]
  );

  console.log(selectedCategoryForEdit);

  const handleSearchInputChange = (query) => {
    setSeacrhQuery(query);
    const filtered = tableData?.filter(({ name }) =>
      name.toLowerCase().includes(query.toLowerCase())
    );
    setFilterTableData(filtered);
  };
  return (
    <>
      <div className="main_section_credit_card_category">
        <div className="head">
          <p className="section_title">Manage Credit Card Category</p>
          <Button
            title={"Add category"}
            onClick={() => {
              setOpenAddCategoryModel(true);
              setSlectedCategoryForEdit(null);
            }}
            className="addBtn"
          >
            Add Category
          </Button>
        </div>

        <div className="table">
          <div className="table-title-search">
            <div className="name">All Categories</div>
            <Searchbar value={seacrhQuery} onchange={handleSearchInputChange} />
          </div>
          {getCategoryLoading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : tableData?.length ? (
            <DataTable columns={columns} data={filterTableData} pagination />
          ) : (
            <div className="data-notfound">Data not Found</div>
          )}
        </div>
      </div>
      {openAddCategoryModel && (
        <AddCreditCardCategoryModel
          setCategoriesUpdated={setCategoriesUpdated}
          setSlectedCategoryForEdit={setSlectedCategoryForEdit}
          setopenEditCreditCardCategoryModel={
            setopenEditCreditCardCategoryModel
          }
          closeModel={() => setOpenAddCategoryModel(false)}
        />
      )}

      {openDeleteModel && (
        <DeleteModel
          message={
            <>
              Are you sure you want to delete this{" "}
              <b>'{deleteSelectedCategory?.name}'</b> and releted all sub
              categories?
            </>
          }
          loading={deleteLoading}
          deleteCallback={deleteCategory}
          closeModel={() => setOpenDeleteModel(false)}
        />
      )}
      {openEditCreditCardCategoryModel && (
        <EditCreditCardCategoryModel
          refreshCategoriesUpdated={setCategoriesUpdated}
          categoryName={selectedCategoryForEdit?.name}
          categoryId={selectedCategoryForEdit?._id}
          closeModel={() => {
            setopenEditCreditCardCategoryModel(false);
          }}
        />
      )}
    </>
  );
}
