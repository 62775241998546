import React, { useRef, useState } from "react";
import "./AddCreditCardCategoryModel.scss";
 
 
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppImages } from "../../../../Images/AppImages";
import { addNewCreditCardCategory } from "../../../../store/creditCardPageSlice";
import Button from "../../../common/Button";
import CustomInput from "../../../common/CustomInput";
 
export default function AddCreditCardCategoryModel({
  setCategoriesUpdated,
  closeModel,
 setSlectedCategoryForEdit,
  setopenEditCreditCardCategoryModel
}) {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const { addEditCategoryLoading: loading } = useSelector(
    (state) => state.CreditCardPageReducer
  );

  const handleClickOutside = (event) => {
    if (
      !loading &&
      formRef.current &&
      !formRef.current.contains(event.target)
    ) {
      closeModel();
    }
  };
  const validate = (updatedData = data) => {
    let tempErrors = {};
    if (!updatedData.name?.trim()) tempErrors.name = "Category is required.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e?.preventDefault();
    if (validate()) {
      dispatch(
        addNewCreditCardCategory({ ...data, name: data?.name?.trim() })
      ).then((res) => {
        if (res?.payload?.status === 422) {
          toast.error(res?.payload?.data.message);
          return;
        }

        if (res?.payload?.status === 201) {
          toast.success("category added succesfully !");
          setCategoriesUpdated((prev) => !prev);

          setopenEditCreditCardCategoryModel?.(true);
          setSlectedCategoryForEdit?.(res?.payload?.data.data);

          closeModel();
          return;
        }
        toast.error("Something went wrong, please try again !");
        closeModel();
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...data, [name]: value };
    setData(updatedData);
    validate(updatedData);
  };
  return (
    <div
      className="main_section_credit_card_category_model "
      onClick={handleClickOutside}
    >
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className="credit_card_category"
      >
        <p className="section_title">Add Credit Card Category</p>
        <div className="container">
          <div className="width_100">
            <CustomInput
              label="Category"
              placeholder={"Enter Category"}
              isCompulsory={true}
              name="name"
              value={data?.name}
              autoFocus={true}
              handleChange={handleChange}
              error={errors.name}
            />
          </div>
          <Button type="submit" className="btn" loading={loading}>
            Add Category
          </Button>
        </div>
        <button onClick={() => closeModel()} className="cross">
          <img src={AppImages.cross} alt="" />
        </button>
      </form>
    </div>
  );
}
