import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROUTES } from "../services/apiRoutes";
import axiosInstance from "../utils/axiosInstance";

const initialState = {
    loading: false,
    footerData: {},
};

export const getFooterData = createAsyncThunk(
    "admin/getFooterData",
    async (thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_FOOTER_DATA);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

export const sendFooterData = createAsyncThunk(
    "admin/sendFooterData",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.put(API_ROUTES.PUT_FOOTER_DATA+ data?._id, data);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const FooterSlice = createSlice({
    name: "FooterSlice",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getFooterData.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getFooterData.fulfilled, (state, action) => {
                state.loading = false;
                state.footerData = action.payload.data.data[0];
            })
            .addCase(getFooterData.rejected, (state, action) => {
                state.loading = false;
            });

        builder
            .addCase(sendFooterData.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(sendFooterData.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(sendFooterData.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

// export const { loginUser, logoutUser } = userSlice.actions;
export default FooterSlice.reducer;
