import React from "react";
import './pageTitle.scss';

export default function PageTitle({ title, style }) {
  return (
    <div id="pageTitle" style={style}>
      <h1 className="title">{title}</h1>
    </div>
  );
}
