import React, { useEffect, useMemo, useState } from "react";
import "./benefitsSection.scss";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import { AppImages } from "../../../Images/AppImages";
import { deleteBenefit, getBenefitsOfCreditAnalysisData } from "../../../store/discoverSlice";
import Button from "../../common/Button";
import DeleteModel from "../../common/DeleteModel";
import Loader from "../../common/Loader";
import Searchbar from "../../common/SearchBar";
import AddEditBenefitModel from "../models/AddEditBenefitModel";

export default function BenefitsSection() {
  const [openAddEditBenefitModel, setOpenAddEditBenefitModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [BenefitUpdated, setBenefitUpdated] = useState(false);

  const [deleteSelectedBenefit, setDeleteSelectedBenefit] = useState(null);
  const [selectdEditBenefitData, setSelectdEditBenefitData] = useState(null);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    getBenefitsOfCreditAnalysisDataLoading,
    benefitsOfCreditAnalysisData,
    deleteLoading,
  } = useSelector((state) => state.discoverReducer);

  const deleteThisBenefit = () => {
    dispatch(deleteBenefit(deleteSelectedBenefit?._id)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("Credit Card Deleted succesfully !");
        setBenefitUpdated((prev) => !prev);
        setOpenDeleteModel(false);
        setDeleteSelectedBenefit(null);
        return;
      }
      toast.error("Something went wrong, please try again !");
      setOpenDeleteModel(false);
      setDeleteSelectedBenefit(null);
    });
  };
  useEffect(() => {
    dispatch(getBenefitsOfCreditAnalysisData());
  }, [BenefitUpdated]);

  useEffect(() => {
    setTableData(benefitsOfCreditAnalysisData);
    setFilterTableData(benefitsOfCreditAnalysisData);
    // setSearchQuery("");
  }, [benefitsOfCreditAnalysisData]);

  const columns = useMemo(
    () => [
      {
        name: "Title",
        selector: (row) => row.banefit_title,
        sortable: true,
      },

      {
        name: "Actions",
        cell: (row) => (
          <div className="editDelete">
            <button
              className="editBtn"
              onClick={() => {
                setSelectdEditBenefitData(row);
                setOpenAddEditBenefitModel(true);
              }}
            >
              <img src={AppImages.edit} />
            </button>
            <button
              onClick={() => {
                setDeleteSelectedBenefit(row);
                setOpenDeleteModel(true);
              }}
              className="deleteBtn"
            >
              <img src={AppImages.deleteBin} />
            </button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    [tableData]
  );

  const handleSearchInputChange = (query) => {
    setSearchQuery(query);
    const filtered = tableData?.filter(({ banefit_title }) =>
      banefit_title.toLowerCase().includes(query.toLowerCase())
    );
    setFilterTableData(filtered);
  };
  return (
    <>
      <div className="BenefitsSection">
        <div className="head">
          <p className="section_title">Manage Benefits</p>

          <Button
            onClick={() => {
              setSelectdEditBenefitData(null);
              setOpenAddEditBenefitModel(true);
            }}
            className="addBtn"
          >
            Add Benefit
          </Button>
        </div>
        <div className="table">
          <div className="table-title-search">
            <div className="name">All Credit Cards</div>

            <Searchbar value={searchQuery} onchange={handleSearchInputChange} />
          </div>
          {getBenefitsOfCreditAnalysisDataLoading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : tableData?.length ? (
            <DataTable columns={columns} data={filterTableData} pagination />
          ) : (
            <div className="data-notfound">Data not Found</div>
          )}
        </div>
      </div>
      {openAddEditBenefitModel && (
        <AddEditBenefitModel
          setBenefitUpdated={setBenefitUpdated}
          editBenefitData={selectdEditBenefitData}
          closeModel={() => setOpenAddEditBenefitModel(false)}
        />
      )}
      {openDeleteModel && (
        <DeleteModel
          message={
            <>
              Are you sure you want to delete{" "}
              <b>'{deleteSelectedBenefit?.name}</b> ?
            </>
          }
          loading={deleteLoading}
          deleteCallback={deleteThisBenefit}
          closeModel={() => setOpenDeleteModel(false)}
        />
      )}
    </>
  );
}
