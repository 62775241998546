import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
 
import toast from "react-hot-toast";
import { getGuidesPageData, sendGuidesPageData } from "../../store/guidePageSlice";
import FrequentlyAskedQuestions from "../../components/GuidesComponents/FrequentlyAskedQuestions";
import RightCards from "../../components/GuidesComponents/RightCards";
import PageDescription from "../../components/GuidesComponents/PageDescription";
import PageTitle from "../../components/common/PageTitle";
import Button from "../../components/common/Button";
 
const GuidesPage = () => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { GuidesData, loading } = useSelector((state) => state.guidesReducer);
  console.log();
  useEffect(() => {
    dispatch(getGuidesPageData());
  }, [dispatch]);

  useEffect(() => {
    setData(GuidesData);
  }, [GuidesData]);

  const validate = (updatedData = data) => {
    console.log(updatedData.aboutus_image);
    console.log(updatedData.card_image);
    let tempErrors = {};

    if (!updatedData.tips_title?.trim())
      tempErrors.tips_title = "Title is required.";
    if (!updatedData.FAQ?.trim()) tempErrors.FAQ = "Name is required.";
    if (!updatedData.tips_link?.trim())
      tempErrors.tips_link = "Video link is required.";
     
    if (!updatedData.tips_discription?.trim())
      tempErrors.tips_discription = "Description is required.";

          if (updatedData?.QA) {
            updatedData.QA?.forEach((QA, index) => {
              if (!QA.answer?.trim())
                tempErrors[`QA[${index}][answer]`] = "Answer is required.";
              if (!QA.question?.trim())
                tempErrors[`QA[${index}][question]`] = "Question is required.";
            
            });
          }
      
    if (updatedData.right_card) {
      updatedData.right_card.forEach((right_card, index) => {
        if (!right_card.title?.trim())
          tempErrors[`right_card[${index}][title]`] = "Title is required.";
        if (!right_card.description?.trim())
          tempErrors[`right_card[${index}][description]`] =
            "Description is required.";
        if (!right_card.image)
          tempErrors[`right_card[${index}][image]`] = "Image is required.";
        else if (
          typeof right_card?.image === "object" &&
          !right_card?.image?.type?.startsWith("image/")
        ) {
          tempErrors[`right_card[${index}][image]`] =
            "File is not a valid image type.";
        }
      });
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e, newValue, arrayName, index, fieldName) => {
    const { name, value } = e.target;

    let updatedData;
    if (arrayName && index !== undefined && fieldName) {
      const updatedArray = [...data[arrayName]];
      updatedArray[index] = {
        ...updatedArray[index],
        [fieldName]: newValue || value,
      };
      updatedData = { ...data, [arrayName]: updatedArray };
    } else {
      updatedData = { ...data, [name]: value };
    }

    setData(updatedData);
    validate(updatedData);
  };

  const handleSubmit = () => {
    if (validate()) {
      const formData = new FormData();
      formData.append("name", data.name);
 
      formData.append("tips_discription", data.tips_discription?.trim());
      formData.append("tips_title", data.tips_title?.trim());
      formData.append("FAQ", data.FAQ?.trim());
      formData.append("tips_link", data.tips_link?.trim());

      for (let i = 0; i < data?.right_card?.length; i++) {
        formData.append(
          `right_card[${i}][title]`,
          data.right_card[i]?.title?.trim()
        );
        formData.append(
          `right_card[${i}][description]`,
          data.right_card[i]?.description?.trim()
        );
        formData.append(`right_card[${i}][image]`, data.right_card[i]?.image);
      }

  
    for (let i = 0; i < data?.QA?.length; i++) {
      formData.append(`QA[${i}][answer]`, data.QA[i]?.answer?.trim());
      formData.append(`QA[${i}][question]`, data.QA[i]?.question?.trim());
    }
      dispatch(sendGuidesPageData(formData)).then((res) => {
        if (res.payload.status && res.payload.status === 200) {
          toast.success("Data updated succesfully !");
        }
      });
    }
  };

  return (
    <React.Fragment>
      <PageTitle title="Guide Page" />

      <PageDescription
        handleChange={handleChange}
        data={data}
        errors={errors}
      />
      <FrequentlyAskedQuestions
        handleChange={handleChange}
        data={data}
        errors={errors}
      />
      <RightCards handleChange={handleChange} data={data} errors={errors} />
      <Button onClick={handleSubmit} loading={loading}>
        Save
      </Button>
    </React.Fragment>
  );
};

export default GuidesPage;
