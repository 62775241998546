import React from "react";
import "./creditCardUserEntries.scss";

import PageTitle from "../../components/common/PageTitle";
import CreditCardUserEntriesData from "../../components/CreditCardUserEntriesComponents/CreditCardUserEntriesData";

export default function CreditCardUserEntries() {
  return (
    <>
      <PageTitle title="Credit Card" />
      <CreditCardUserEntriesData />
    </>
  );
}
