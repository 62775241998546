import React, { useState } from "react";
import CustomInput from "../../common/CustomInput";

const OurServiceSection = ({ handleChange, data, errors }) => {
  return (
    <div className="main_section_img_title">
      <p className="section_title">Our Services</p>
      {data?.service &&
        data?.service.map((serviceSingle, index) => {
          return (
            <>
              <p className="listInputTitle">Service {index + 1}</p>
              <div className="titleAndDescription">
                <div className="width_50">
                  <CustomInput
                    label="Title"
                    isCompulsory={true}
                    name={`service[${index}][title]`}
                    handleChange={(e) =>
                      handleChange(e, undefined, "service", index, "title")
                    }
                    value={serviceSingle.title}
                    error={errors[`service[${index}][title]`]}
                  />
                </div>
                <div className="width_50">
                  <CustomInput
                    label="Description"
                    isCompulsory={true}
                    handleChange={(e) =>
                      handleChange(
                        e,
                        undefined,
                        "service",
                        index,
                        "description"
                      )
                    }
                    name="services"
                    value={serviceSingle.description}
                    error={errors[`service[${index}][description]`]}
                  />
                </div>
                <div className="width_50">
                  <CustomInput
                    label="Button Link"
                    isCompulsory={true}
                    name={`service_button_link${index}`}
                    handleChange={(e) =>
                      handleChange(
                        e,
                        undefined,
                        "service",
                        index,
                        "button_link"
                      )
                    }
                    value={serviceSingle?.button_link}
                    error={errors[`service[${index}][button_link]`]}
                  />
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default OurServiceSection;
