import {   useRef } from "react";
 

import "./searchbar.scss";
import { useState } from "react";
import { AppImages } from "../../../Images/AppImages";

function Searchbar({ value = "", onchange, placeHolder, autoFocus = false }) {
  const ref = useRef();
  const [searchQuery, setSearchQuery] = useState(value);
  return (
    <div htmlFor="search-app " className="search-bar">
      <label htmlFor="search-app">
        <img src={AppImages.search} alt="" />
      </label>
      <input
        value={searchQuery}
        ref={ref}
        autoFocus={autoFocus}
        id="search-app"
        type="text"
        placeholder={placeHolder || "Search"}
        onChange={(e) => {
          onchange(e.target.value);
          setSearchQuery(e.target.value);
        }}
      />
      <label>
        {(ref?.current?.value || searchQuery) && (
          <img
            height={22}
            onClick={() => {
              // for clear the input field
              onchange?.("");
              ref.current.value = "";
              setSearchQuery("");
            }}
            src={AppImages.crossIcon}
            alt=""
          />
        )}
      </label>
    </div>
  );
}

export default Searchbar;
