import React from "react";
import "./Loader.scss";
const Loader = () => {
  return (
    <div class="simple-spinner">
      <span></span>
    </div>
  );
};

export default Loader;
