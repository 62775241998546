import { createSlice } from '@reduxjs/toolkit';
import { getLocalItem, removeLocalItem, setLocalItem } from '../utils/helper';

const initialState = {
  isUserLogedIn: getLocalItem("Token") ? true : false,
};

export const userSlice = createSlice({
  name: 'user slice',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.isUserLogedIn = true;
      setLocalItem("Token", action.payload.token);     
    },
    logoutUser: (state) => {
      removeLocalItem("Token");
      localStorage.clear()
        state.isUserLogedIn = false;
    },
  },
});

export const { loginUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;
