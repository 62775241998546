import React, { useState } from "react";
import "./exclusiveCardSection.scss";
import CustomInput from "../../common/CustomInput";

const ExclusiveCardSection = ({ handleChange, data, errors }) => {
  const [previewImage, setPreviewImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
        handleChange({ target: { name: "card_image", value: file } });
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
      handleChange({ target: { name: "card_image", value: null } });
    }
  };

  return (
    <div className="main_section_img_title">
      <p className="section_title">Exclusive Cards Section</p>
      <div className="titleAndDescription">
        <div className="width_50">
          <CustomInput
            label="Title"
            name="card_title"
            isCompulsory={true}
            handleChange={handleChange}
            value={data.card_title}
            error={errors.card_title}
          />
        </div>

        <div className="width_50">
          <CustomInput
            label="Button Link"
            isCompulsory={true}
            name="exlusive_button_link"
            handleChange={handleChange}
            value={data?.exlusive_button_link}
            error={errors.exlusive_button_link}
          />
        </div>
        <div className="width_50">
          <CustomInput
            label="Image"
            type="file"
            isCompulsory={true}
            accept="image/*"
            style={{
              padding: "13px",
            }}
            handleChange={handleImageChange}
            name="card_image"
            error={errors.card_image}
          />
          {previewImage && previewImage.startsWith("data:") ? (
            <img src={previewImage} alt="Preview" className="preview-image" />
          ) : data.card_image ? (
            <img
              src={data.card_image}
              alt="Preview"
              className="preview-image"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ExclusiveCardSection;
