import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppImages } from "../../../Images/AppImages";
import {
  deleteCreditCardSubCategory,
  editCreditCardSubCategory,
} from "../../../store/creditCardPageSlice";
import Button from "../../common/Button";
import CustomInput from "../../common/CustomInput";
import DeleteModel from "../../common/DeleteModel";
import "./editDeleteSubCategory.scss";
export default function EditDeleteSubCategory(
 { subCategory=[],
  setCategoriesUpdated = () => {}}
) {
  const dispatch = useDispatch();
  const [editLoader, seteditLoader] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState(subCategory?.name);
  const [subCategoryError, setSubCategoryError] = useState("");
  const { deleteLoading } = useSelector(
    (state) => state.CreditCardPageReducer
  );
  const deleteSubcategory = () => {
    dispatch(deleteCreditCardSubCategory(subCategory._id)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("subCategory deleted succesfully !");
        setOpenDeleteModel(false);
        setCategoriesUpdated((prev) => !prev);
        return;
        }
     
      toast.error("Something went wrong, please try again !");
    });
  };

  const editSubcategory = (e) => {
    e.preventDefault();
    if (!subCategoryName.trim()) {
      setSubCategoryError("Sub category is required");
      return;
    }
    setSubCategoryError("");
      seteditLoader(true);
    dispatch(
      editCreditCardSubCategory({
        id: subCategory._id,
        data: {
          categoryId: subCategory.CategoryId,
          name: subCategoryName?.trim(),
        },
      })
    ).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("subCategory updated succesfully !");
        seteditLoader(false);
        setCategoriesUpdated((prev) => !prev);
        return;
      }
      if (res?.payload?.status === 422) {
        console.log(res);
        toast.error(res?.payload?.data?.message);
       seteditLoader(false);
        return;
      }
      seteditLoader(false);
      toast.error("Something went wrong, please try again !");
    });
  };
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  //  {
  //             "_id": "66697dbd5555c7551152c887",
  //             "name": "newSub",
  //             "CategoryId": "66697c4e5555c7551152c86d",
  //             "createdAt": "2024-06-12T10:51:41.835Z",
  //             "updatedAt": "2024-06-12T10:51:41.835Z",
  //             "__v": 0
  //         },

  return (
    <>
      <form onSubmit={editSubcategory} className="main-section-subcategory">
        <div className="width-100">
          <CustomInput
            name="sub_category"
            error={subCategoryError}
            placeholder={"Enter Sub category"}
            className={"width-100"}
            isCompulsory={true}
            value={subCategoryName}
            handleChange={(e) => setSubCategoryName(e.target.value)}
          />
        </div>
        <div className="btn-container">
          <Button
            type="submit"
            title={"Update subcategory"}
            disabled={subCategoryName === ""  }
            className="edit-btn"
            loading={editLoader}
          >
            Update
          </Button>
          <button
            onClick={() => setOpenDeleteModel(true)}
            type="button"
            title="Delete subcategory"
            className="deleteBtn"
          >
            <img src={AppImages.deleteBin} />
          </button>
        </div>
      </form>
      {openDeleteModel && (
        <DeleteModel
          loading={deleteLoading}
          message={<>Are you sure you want to delete this <b>'{subCategoryName}'</b>?</>}
          closeModel={() => setOpenDeleteModel(false)}
          deleteCallback={() => {
            deleteSubcategory();
          }}
        />
      )}
    </>
  );
}
