import React from "react";
import CustomInput from "../../common/CustomInput";

const OurGuideSection = ({ handleChange, data, errors }) => {
  return (
    <div className="main_section_img_title">
      <p className="section_title">Guide Section</p>
      <div className="titleAndDescription">
        <div className="width_50">
          <CustomInput
            label="Title"
            name="guide_title"
            isCompulsory={true}
            handleChange={handleChange}
            value={data.guide_title}
            error={errors.guide_title}
          />
        </div>

        {/* <div className="width_50">
          <CustomInput
            label="Button Link"
            name="read_our_button_link"
            handleChange={handleChange}
            value={data?.read_our_button_link}
            error={errors.read_our_button_link}
          />
        </div> */}
      </div>
    </div>
  );
};

export default OurGuideSection;
