import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROUTES } from "../services/apiRoutes";
import axiosInstance from "../utils/axiosInstance";

const initialState = {
    heroDiscoverPageDataLoading: false,
    heroDiscoverPageData: {},
    benefitsOfCreditAnalysisData:[],
    getBenefitsOfCreditAnalysisDataLoading: false,
    addEditBenifitLoading: false,
    deleteLoading:false
};

export const getDiscoverHeroData = createAsyncThunk(
    "admin/getDiscoverHeroData",
    async (thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_HERO_DISCOVER_PAGE_DATA);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);


export const sendDiscoverHeroData = createAsyncThunk(
    "admin/sendDiscoverHeroData",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.put(API_ROUTES.PUT_HERO_DISCOVER_PAGE_DATA, data);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

// benefits

export const getBenefitsOfCreditAnalysisData = createAsyncThunk(
    "admin/getBenefitsOfCreditAnalysisData",
    async (thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_BENEFITS);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

export const editBenefit = createAsyncThunk(
    "admin/editBenefit",
    async (data,thunkAPI) => {
        try {
            const response = await axiosInstance.put(API_ROUTES.PUT_BENEFIT+data._id,data);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const addBenefit = createAsyncThunk(
    "admin/addBenefit",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.post(API_ROUTES.POST_BENEFIT, data);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const deleteBenefit = createAsyncThunk(
    "admin/deleteBenefit",
    async (id, thunkAPI) => {
        try {
            const response = await axiosInstance.delete(API_ROUTES.DELETE_BENEFIT+id);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

export const DiscoverSlice = createSlice({
    name: "DiscoverSlice",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getDiscoverHeroData.pending, (state, action) => {
                state.heroDiscoverPageDataLoading = true;
            })
            .addCase(getDiscoverHeroData.fulfilled, (state, action) => {
                state.heroDiscoverPageDataLoading = false;
                state.heroDiscoverPageData = action.payload.data.data;
            })
            .addCase(getDiscoverHeroData.rejected, (state, action) => {
                state.heroDiscoverPageDataLoading = false;
            });

        builder
            .addCase(sendDiscoverHeroData.pending, (state, action) => {
                state.heroDiscoverPageDataLoading = true;
            })
            .addCase(sendDiscoverHeroData.fulfilled, (state, action) => {
                state.heroDiscoverPageDataLoading = false;
            })
            .addCase(sendDiscoverHeroData.rejected, (state, action) => {
                state.heroDiscoverPageDataLoading = false;
            });
        
        // BenefitsOfCreditAnalysisData
        builder
            .addCase(getBenefitsOfCreditAnalysisData.pending, (state, action) => {
                state.getBenefitsOfCreditAnalysisDataLoading = true;
            })
            .addCase(getBenefitsOfCreditAnalysisData.fulfilled, (state, action) => {
                state.getBenefitsOfCreditAnalysisDataLoading = false;
                state.benefitsOfCreditAnalysisData = action.payload.data.data;
            })
            .addCase(getBenefitsOfCreditAnalysisData.rejected, (state, action) => {
                state.getBenefitsOfCreditAnalysisDataLoading = false;
            });

        
      
        
        builder
            .addCase(editBenefit.pending, (state, action) => {
                state.addEditBenifitLoading = true;
            })
            .addCase(editBenefit.fulfilled, (state, action) => {
                state.addEditBenifitLoading = false;
            })
            .addCase(editBenefit.rejected, (state, action) => {
                state.addEditBenifitLoading = false;
            });
        builder
            .addCase(addBenefit.pending, (state, action) => {
                state.addEditBenifitLoading = true;
            })
            .addCase(addBenefit.fulfilled, (state, action) => {
                state.addEditBenifitLoading = false;
            })
            .addCase(addBenefit.rejected, (state, action) => {
                state.addEditBenifitLoading = false;
            });
        builder
            .addCase(deleteBenefit.pending, (state, action) => {

                state.deleteLoading = true;
            })
            .addCase(deleteBenefit.fulfilled, (state, action) => {
                state.deleteLoading = false;
            })
            .addCase(deleteBenefit.rejected, (state, action) => {
                state.deleteLoading = false;
            });
    }, 
});

// export const { loginUser, logoutUser } = userSlice.actions;
export default DiscoverSlice.reducer;
