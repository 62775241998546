import React, { useState } from "react";
 
import CustomInput from "../../common/CustomInput";
import "./rightCards.scss";
const RightCards = ({ handleChange, data, errors }) => {
  const [previews, setPreviews] = useState([]);

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviews((prevPreviews) => {
          const newPreviews = [...prevPreviews];
          newPreviews[index] = reader.result;
          return newPreviews;
        });
      };
      reader.readAsDataURL(file);
      handleChange(e, file, "right_card", index, "image");
    }
  };

  return (
    <div className="main_section_right_cards">
      <p className="section_title"> Right Card Section</p>
      {data?.right_card &&
        data?.right_card.map((rightCardSingle, index) => {
          return (
            <>
              <p className="listInputTitle">Right Card {index + 1}</p>
              <div className="titleAndDescription">
                <div className="width_50">
                  <CustomInput
                    label="Title"
                    name={`right_card[${index}][title]`}
                    isCompulsory={true}
                    handleChange={(e) =>
                      handleChange(e, undefined, "right_card", index, "title")
                    }
                    value={rightCardSingle.title}
                    error={errors[`right_card[${index}][title]`]}
                  />
                </div>

                <div className="width_50">
                  <CustomInput
                    label="Description"
                    isCompulsory={true}
                    handleChange={(e) =>
                      handleChange(
                        e,
                        undefined,
                        "right_card",
                        index,
                        "description"
                      )
                    }
                    value={rightCardSingle.description}
                    error={errors[`right_card[${index}][description]`]}
                  />
                </div>

                <div className="width_50">
                  <CustomInput
                    label="Image"
                    type="file"
                    isCompulsory={true}
                    handleChange={(e) => handleImageChange(e, index)}
                    error={errors[`right_card[${index}][image]`]}
                  />
                  {(previews[index] || rightCardSingle.image) && (
                    <img
                      src={
                        previews[index]
                          ? previews[index]
                          : rightCardSingle.image
                      }
                      alt="Preview"
                      className="preview-image "
                    />
                  )}
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default RightCards;
