import React, { useMemo } from "react";
 
import Select from "react-select";
 
import "./dropdownIdValue.scss";
export default function DropDownIdValue({
  options = [],
  name,
  label,
  isDisabled,
  isCompulsory = false,
  value,
  handleChange,
  error,
}) {
  const dropdownOptions = useMemo(
    () =>
      options?.map((option) => ({
        value: option?._id,
        label: option?.name,
      })),
    [options]
  );
 const customStyles = {
   control: (provided) => ({
     ...provided,
      cursor: "pointer"
   }),
   
 
 };
  
  return (
    <div className="dropdownIdValue">
      <div className="label">
        {label} {isCompulsory && label && <span> *</span>}
      </div>
      <Select
        isDisabled={isDisabled}
        isClearable={true}
        styles={customStyles}
        options={dropdownOptions}
        onChange={(option) => handleChange({ target: { value: option, name } })}
        value={value}
        placeholder={"Select an option"}
      />
      {error && <p className={`errormsg`}>{error}</p>}
    </div>
  );
}
