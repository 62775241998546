import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppImages } from "../../../../Images/AppImages";
import {
  addNewCreditCardSubCategory,
  editCreditCardCategory,
  getCreditCardSubCategoryByCategoryId,
} from "../../../../store/creditCardPageSlice";
import Button from "../../../common/Button";
import CustomInput from "../../../common/CustomInput";
import Loader from "../../../common/Loader";
import EditDeleteSubCategory from "../../EditDeleteSubCategory";
import "./editcreditCardCategoryModel.scss";
export default function EditCreditCardCategoryModel({
  categoryId,
  categoryName = "",
  closeModel,
  refreshCategoriesUpdated,
}) {
  const {
 
    addSubCategoryLoading,
    addEditCategoryLoading,
  } = useSelector((state) => state.CreditCardPageReducer);
  const ref = useRef(null);
  const [category, setCategory] = useState(categoryName);
  const [newSubcategory, setNewSubcategory] = useState("");
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [newSubcategoryError, setNewSubcategoryError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [categoriesUpdated, setCategoriesUpdated] = useState(false);
  const [getSubcategoryLoaader, setGetSubcategoryLoaader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setGetSubcategoryLoaader(true);
    }, 900);

    dispatch(getCreditCardSubCategoryByCategoryId(categoryId)).then((res) => {
      if (res?.payload?.status === 200) {
       
        setAllSubCategories( res?.payload?.data.data);
        clearTimeout(timeoutId);
        setGetSubcategoryLoaader(false);
        return;
      }
      toast.error("Something went wrong, please try again !");

      clearTimeout(timeoutId);
      setGetSubcategoryLoaader(false);
    });

    return () => {
      clearTimeout(timeoutId);
      setGetSubcategoryLoaader(false);
    };
  }, [categoriesUpdated]);

  // useEffect(() => {
  //   setAllSubCategories(CreditCardSubCategoriesOfCategory);
  // }, [CreditCardSubCategoriesOfCategory]);
  const updateCategory = (e) => {
    e.preventDefault();
    if (!category.trim()) {
      setCategoryError("Category is required");
      return;
    }
    setCategoryError("");
    dispatch(
      editCreditCardCategory({
        id: categoryId,
        name: category,
      })
    ).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("category updated succesfully !");
        refreshCategoriesUpdated((prev) => !prev);
        setCategoriesUpdated((prev) => !prev);
        return;
      }
      if (res?.payload?.status === 422) {
        console.log(res);
        toast.error(res?.payload?.data?.message);

        return;
      }
      toast.error("Something went wrong, please try again !");
    });
  };
  const addSubCategory = (e) => {
    e.preventDefault();
    if (!newSubcategory.trim()) {
      setNewSubcategoryError("Name is required");
      return;
    }
    setNewSubcategoryError("");
    dispatch(
      addNewCreditCardSubCategory({
        name: newSubcategory?.trim(),
        categoryId: categoryId,
      })
    ).then((res) => {
      if (res?.payload?.status === 201) {
        toast.success("New Sub category Added succesfully !");
        setCategoriesUpdated((prev) => !prev);
        setNewSubcategory("");
        return;
      }
      if (res?.payload?.status === 422) {
        console.log(res);
        toast.error(res?.payload?.data?.message);

        return;
      }
      toast.error("Something went wrong, please try again !");
    });
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      //   closeModel?.();
    }
  };
  console.log(allSubCategories)
  return (
    <div className="EditCreditCardModel" onClick={handleClickOutside}>
      <div className="wrapper">
        <div
          ref={ref}
          // onSubmit={handleSubmit}
          className="credit_card_category"
        >
          <p className="section_title">Update Credit Card Category</p>
          <form onSubmit={updateCategory} className="edit-category-container ">
            <CustomInput
              label="Edit Category"
              placeholder={"Enter Category"}
              name="name"
              value={category}
              error={categoryError}
              isCompulsory={true}
              autoFocus={true}
              handleChange={(e) => setCategory(e.target.value)}
            />
            <Button
              className="edit-category-btn"
              title={"Edit Category"}
              type="submit"
              loading={addEditCategoryLoading}
            >
              Update Category
            </Button>
          </form>
          <p className="title">All Sub Categories</p>
          <div className="all-sub-categories">
            <form onSubmit={addSubCategory} className="add-new-subcategory">
              <div className="width-100">
                <CustomInput
                  name="sub_category"
                  label={"Enter New Subcategory"}
                  isCompulsory={true}
                  placeholder={"Enter New Subcategory"}
                  className={"width-100 border-color"}
                  error={newSubcategoryError}
                  value={newSubcategory}
                  handleChange={(e) => setNewSubcategory(e.target.value)}
                />
              </div>
              <div className="btn-container">
                <Button
                  title={"Add new subcategory"}
                  className="add-subcategory-btn"
                  loading={addSubCategoryLoading}
                  type="submit"
                >
                  Add
                </Button>
              </div>
            </form>

            {!getSubcategoryLoaader ? (
              allSubCategories?.map((subCategory) => (
                <>
                  {console.log(allSubCategories, "data")}
                  <EditDeleteSubCategory
                    setCategoriesUpdated={setCategoriesUpdated}
                    subCategory={subCategory}
                    key={subCategory._id}
                  />
                </>
              ))
            ) : (
              <div className="loader">
                <Loader />
              </div>
            )}
          </div>
        </div>

        <button onClick={() => closeModel?.()} className="cross">
          <img src={AppImages.cross} alt="" />
        </button>
      </div>
    </div>
  );
}
