import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROUTES } from "../services/apiRoutes";
import axiosInstance from "../utils/axiosInstance";

const initialState = {
    loading: false,
    GuidesData: {},
};

export const getGuidesPageData = createAsyncThunk(
    "admin/getGuidesPageData",
    async (thunkAPI) => {
        try {
            const response = await axiosInstance.get(API_ROUTES.GET_GUIDE_PAGE_DATA);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);

export const sendGuidesPageData = createAsyncThunk(
    "admin/sendGuidesPageData",
    async (data, thunkAPI) => {
        try {
            const response = await axiosInstance.put(API_ROUTES.PUT_GUIDE_PAGE_DATA, data);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response); // Reject with an error message
        }
    }
);
export const GuidesPageSlice = createSlice({
    name: "GuidesPageSlice",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getGuidesPageData.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getGuidesPageData.fulfilled, (state, action) => {
                state.loading = false;
                state.GuidesData = action.payload.data.data;
            })
            .addCase(getGuidesPageData.rejected, (state, action) => {
                state.loading = false;
            });

        builder
            .addCase(sendGuidesPageData.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(sendGuidesPageData.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(sendGuidesPageData.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

// export const { loginUser, logoutUser } = userSlice.actions;
export default GuidesPageSlice.reducer;
