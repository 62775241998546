import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppImages } from "../../../../Images/AppImages";
import {
  addNewCreditCard,
  getCreditCardCategories,
  editCreditCardData as sendEditCreditCardData,
} from "../../../../store/creditCardPageSlice";
import Button from "../../../common/Button";
import CustomInput from "../../../common/CustomInput";
import DropDownIdValue from "../../../common/DropDownIdValue";
import "./AddEditCreditCardModel.scss";
export default function AddEditCreditCardModel({
  editCreditCardData,
  setCreditCardUpdated,
  closeModel,
}) {
  const formRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
 
  const [creditCardCategoriesData, setCreditCardCategoriesData] = useState([]);
  const [data, setData] = useState({});
  console.log(data, "data state");
  console.log(editCreditCardData);
  const {
    creditCardData: {   creditCardCategories },
   
    addEditCreditCardLoading,
  } = useSelector((state) => state.CreditCardPageReducer);
  // console.log(data);
  useEffect(() => {
    if (!editCreditCardData) return;
    setData({
      ...editCreditCardData,
      category: {
        value: editCreditCardData?.category?._id,
        label: editCreditCardData?.category?.name,
      },
      subcategory: {
        value: editCreditCardData?.subcategory?._id,
        label: editCreditCardData?.subcategory?.name,
      },
      affiliateLink:
        editCreditCardData?.affiliateLink === "NULL"
          ? ""
          : editCreditCardData?.affiliateLink,
    });

    // dispatch(getCreditCardData());
  }, []);

  useEffect(() => {
    dispatch(getCreditCardCategories());
  }, []);

  useEffect(() => {
    setCreditCardCategoriesData(creditCardCategories);
  }, [creditCardCategories]);
  // useEffect(() => {
  //   if (!editCreditCardData) return;
  //   setData(creditCard);
  // }, [creditCard]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // console.log(file?.type?.startsWith("image/"));
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
        handleChange({ target: { name: "card_image", value: file } });
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
      handleChange({ target: { name: "card_image", value: null } });
    }
  };
  console.log(data);
  const validate = (updatedData = data) => {
    let tempErrors = {};
    if (!updatedData.name?.trim()) tempErrors.name = "Name is required.";
    if (!updatedData.annualFee?.trim())
      tempErrors.annualFee = "Annual Fee is required.";
    if (!updatedData.balanceTransferIntroAPR?.trim())
      tempErrors.balanceTransferIntroAPR =
        "Balance transfer intro APR is required.";
    if (!updatedData.regularAPR?.trim())
      tempErrors.regularAPR = "Regular APR is required.";
    if (!updatedData.recommendedCredit?.trim())
      tempErrors.recommendedCredit = "Recommended Credit is required.";
    if (!updatedData.rewardsRate?.trim())
      tempErrors.rewardsRate = "Rewards Rate is required.";
    // if (!updatedData.affiliateLink)
    //   tempErrors.affiliateLink = "Affiliate Link is required.";
    if (!updatedData?.category?.value)
      tempErrors.category = "Category is required.";
    if (!updatedData?.subcategory?.value)
      tempErrors.subcategory = "Sub category is required.";
    if (!updatedData.card_image) {
      tempErrors.card_image = "Image is required.";
    } else if (
      typeof updatedData.card_image === "object" &&
      !updatedData?.card_image?.type?.startsWith("image/")
    ) {
      tempErrors.card_image = "File must be Image type.";
    }
    if (!updatedData.description?.trim())
      tempErrors.description = "Card Description is required.";
     
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  
  const handleSubmit = (e) => {
    e?.preventDefault();
  

    if (validate()) {
      console.log("click2");

      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("annualFee", data.annualFee?.trim());
      formData.append(
        "balanceTransferIntroAPR",
        data.balanceTransferIntroAPR?.trim()
      );
      formData.append("regularAPR", data.regularAPR?.trim());
      formData.append("recommendedCredit", data.recommendedCredit?.trim());
      formData.append("rewardsRate", data.rewardsRate?.trim());
      formData.append(
        "affiliateLink",
        data?.affiliateLink?.trim() === "" || data?.affiliateLink?.trim() === "NULL"
          ? "NULL"
          : data?.affiliateLink?.trim()
      );
      formData.append("category", data.category.value?.trim());
      formData.append("subcategory", data?.subcategory?.value?.trim());
      formData.append("card_image", data.card_image);
      formData.append("description", data.description?.trim());
      if (editCreditCardData) {
        console.log("edit");

        dispatch(
          sendEditCreditCardData({
            id: editCreditCardData._id,
            formData: formData,
          })
        ).then((res) => {
          console.log(res, "resEdit");
          if (res.payload.status && res.payload.status === 200) {
            toast.success("Credit Card  Added succesfully !");
            setCreditCardUpdated((prev) => !prev);
            closeModel?.();
          }
        });
      } else {
        console.log("add");
        dispatch(addNewCreditCard(formData)).then((res) => {
          console.log(res, "resAdd");
          if (res.payload.status && res.payload.status === 201) {
            setCreditCardUpdated((prev) => !prev);

            toast.success("Credit Card updated succesfully !");
            closeModel?.();
          }
        });
      }
    }
  };
  const handleChange = (e) => {
    console.log(e.target.name, "EVENTS FIRE");
    let newData = { ...data };
    if (e.target.name === "category") {
      // setData({
      //   ...data,
      //   subcategory: {
      //     label: "",
      //     value: "",
      //   },
      // });
      newData = {
        ...data,
        [e.target.name]: { ...e.target.value },
        subcategory: {
          label: "",
          value: "",
        },
      };
      // setData(newData);
     
      setData(newData);
      return 0;
    }
    const { name, value } = e.target;

    let updatedData = {};
    // if (name === "category") {
    //   data.subcategory = undefined;
    // }
    if (name === "subcategory" || name === "category") {
      updatedData = { ...data, [name]: { ...value } };
    } else {
      updatedData = { ...data, [name]: value };
    }

    setData(updatedData);
    if (editCreditCardData) validate(updatedData);
  };
  console.log(data);
  const handleClickOutside = (event) => {
    // if (
    //   !loading &&
    //   formRef.current &&
    //   !formRef.current.contains(event.target)
    // ) {
    //   closeModel();
    // }
  };
  const handleOptionChange = (option) => {
    handleChange({ target: { name: "card_image", value: null } });
  };

  console.log(data?.category);
  return (
    <div className="AddEditCreditCardModel" onClick={handleClickOutside}>
      <div className="wrapper">
        <form ref={formRef} onSubmit={handleSubmit} className="credit_card">
          <p className="section_title">
            {editCreditCardData ? "Edit" : "Add "} Credit Card{" "}
          </p>
          <div className="titleAndDescription">
            <div className="width_100">
              <CustomInput
                label="Cradit Card Name"
                isCompulsory={true}
                name="name"
                handleChange={handleChange}
                value={data?.name}
                error={errors.name}
              />
            </div>
            <div className="width_100">
              <CustomInput
                label="Credit Card Heading"
                isCompulsory={true}
                handleChange={handleChange}
                name="rewardsRate"
                value={data?.rewardsRate}
                error={errors.rewardsRate}
              />
            </div>
            <div className="width_100">
              <CustomInput
                label="Annual Fee"
                isCompulsory={true}
                name="annualFee"
                handleChange={handleChange}
                value={data?.annualFee}
                error={errors.annualFee}
              />
            </div>

            <div className="width_100">
              <CustomInput
                label="Balance transfer intro APR"
                isCompulsory={true}
                handleChange={handleChange}
                name="balanceTransferIntroAPR"
                value={data?.balanceTransferIntroAPR}
                error={errors.balanceTransferIntroAPR}
              />
            </div>
            <div className="width_100">
              <CustomInput
                label="Regular APR"
                isCompulsory={true}
                handleChange={handleChange}
                name="regularAPR"
                value={data?.regularAPR}
                error={errors.regularAPR}
              />
            </div>
            <div className="width_100">
              <CustomInput
                label="Recommended credit"
                handleChange={handleChange}
                isCompulsory={true}
                name="recommendedCredit"
                value={data?.recommendedCredit}
                error={errors.recommendedCredit}
              />
            </div>

            <div className="width_100">
              <CustomInput
                label="Affiliate Link"
                handleChange={handleChange}
                name="affiliateLink"
                value={data?.affiliateLink}
                error={errors.affiliateLink}
              />
            </div>

            <div className="width_100">
              <CustomInput
                label="Description"
                isCompulsory={true}
                handleChange={handleChange}
                name="description"
                value={data?.description}
                error={errors.description}
              />{" "}
            </div>
            <div className="dropDown-container">
              <DropDownIdValue
                label={"Category"}
                isCompulsory={true}
                value={
                  editCreditCardData
                    ? {
                        value: data?.category?.value,
                        label: data?.category?.label,
                      }
                    : undefined
                }
                options={creditCardCategoriesData}
                name="category"
                handleChange={(e) => {
                  console.log(e);
                  handleChange(e);
                  console.log(e);
                }}
                error={errors.category}
              />
            </div>

            <div className="dropDown-container">
              {editCreditCardData ? (
                <DropDownIdValue
                  label={"Sub category"}
                  isCompulsory={true}
                  value={
                    !(data?.subcategory?.value === "")
                      ? {
                          value: data?.subcategory?.value,
                          label: data?.subcategory?.label,
                        }
                      : null
                  }
                  options={
                    creditCardCategoriesData?.find(
                      ({ _id }) => _id === data?.category?.value
                    )?.subcategories
                  }
                  name="subcategory"
                  handleChange={(e) => {
                    handleChange(e);
                    console.log(e);
                  }}
                  error={errors.subcategory}
                />
              ) : (
                <DropDownIdValue
                  label={"Sub category"}
                  isCompulsory={true}
                  value={
                    data?.subcategory?.value
                      ? {
                          value: data?.subcategory?.value,
                          label: data?.subcategory?.label,
                        }
                      : null
                  }
                  options={
                    creditCardCategoriesData?.find(
                      ({ _id }) => _id === data?.category?.value
                    )?.subcategories
                  }
                  name="subcategory"
                  handleChange={(e) => {
                    handleChange(e);
                    console.log(e);
                  }}
                  error={errors.subcategory}
                />
              )}
            </div>

            <div className="width_100">
              <CustomInput
                label="Image"
                type="file"
                isCompulsory={true}
                accept="image/*"
                style={{
                  padding: "13px",
                }}
                handleChange={handleImageChange}
                name="card_image"
                error={errors.card_image}
              />

              {previewImage && previewImage?.startsWith("data:") ? (
                data.card_image?.type.startsWith("image/") ? (
                  <img
                    src={previewImage}
                    alt="Preview"
                    className="preview-image"
                  />
                ) : null
              ) : data?.card_image ? (
                <img
                  src={data?.card_image}
                  alt="Preview"
                  className="preview-image"
                />
              ) : null}
            </div>

            {/* 
        <div className="width_50">
          <CustomInput
            label="Button Link"
            name="hero_button_link"
            handleChange={handleChange}
            value={data.hero_button_link}
            error={errors.hero_button_link}
          />
        </div> */}
          </div>
          <Button
            type="submit"
            className="btn"
            loading={addEditCreditCardLoading}
          >
            {editCreditCardData ? "Save" : "Add"}
          </Button>
        </form>

        <button onClick={() => closeModel?.()} className="cross">
          <img src={AppImages.cross} alt="" />
        </button>
      </div>
    </div>
  );
}
