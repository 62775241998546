import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pageListData: []
}

export const pageListDataSlice = createSlice({
    name: "page slice",
    initialState,
    reducers: {
        setPageListData: (state, action) =>{
            state.pageListData = action.payload
        }
    }
})

export const {setPageListData} = pageListDataSlice.actions;
export default pageListDataSlice.reducer;