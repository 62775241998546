import React from "react";
import "./customInput.scss";

export default function CustomInput({
  title,
  maxChar,
  isCompulsory = false,
  value,
  accept,
  autoFocus,
  handleChange,
  error,
  id,
  disabled,
  label,
  name,
  style,
  className,
  placeholder,
  type,
}) {
  return (
    <div className="input-container-main" id="input-container-main">
      <label>
        {label}
        {isCompulsory && label && <span> *</span>}
      </label>
      <input
        value={value}
        title={title}
        maxLength={maxChar}
        autoFocus={autoFocus}
        className={` input-field ${className}`}
        type={type}
        accept={accept}
        onChange={handleChange}
        disabled={disabled}
        name={name}
        id={id}
        placeholder={placeholder}
        style={style}
      />
      {error && <p className={`errmsg`}>{error}</p>}
    </div>
  );
}
