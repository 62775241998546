import React from "react";

 

import BenefitsSection from "../../components/DiscoverComponents/BenefitsSection";
import HeroContent from "../../components/DiscoverComponents/HeroContent";
import PageTitle from "../../components/common/PageTitle";

const DiscoverPage = () => {
  return (
    <React.Fragment>
      <PageTitle title="Home Page" />

          <HeroContent />
           <BenefitsSection/>
    </React.Fragment>
  );
};

export default DiscoverPage;
