import Profile from './profile.svg'
import DownArrow from './caret-down-solid.svg'
import cross from"./cross.svg"
import edit from "./edit.svg";
import alert from "./alert.svg";
import search from "./search.svg";
import crossIcon from "./crossIocn.svg";
import passwordHide from './password-hide.svg';
import passwordShow from './password-show.svg';
import deleteBin from "./delete.svg";
export const AppImages = {
    Profile,
    DownArrow,
    cross,
    edit,
    deleteBin,
    alert,
    search,
    crossIcon,
    passwordHide,
    passwordShow


}