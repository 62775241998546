import toast from "react-hot-toast";

export const customHandleChange = (
  name,
  value,
  state,
  setState,
  error,
  setError,
  isValid
) => {
  setError({ ...error, [name]: isValid(value) });
  setState({ ...state, [name]: value });
  console.log(error);
};

export const multiDataHandle = (
  value,
  name,
  index,
  stateData,
  setData,
  errors,
  setError,
  isEmpty
) => {
  //error change
  const errorItem = { ...errors[index] };
  errorItem[name] = isEmpty(value);
  const updatedErrors = [...errors];
  updatedErrors[index] = errorItem;
  setError(updatedErrors);

  //state chnage
  const updatedItem = { ...stateData[index] };
  updatedItem[name] = value;
  const updatedStateData = [...stateData];
  updatedStateData[index] = updatedItem;
  setData(updatedStateData);
};
export const copyToClipboard = (jsonData,toastMessage  ) => {
  navigator.clipboard
    .writeText(typeof jsonData === 'object' ? JSON.stringify(jsonData, null, 2) : jsonData)
    .then(() => toast.success(toastMessage || 'Copied to clipboard'))
    .catch(() => toast.error('Unable to copy  data to clipboard'));
};
export const getLocalItem = (name) => {
  const item = localStorage.getItem(name);
  return item;
};

export const setLocalItem = (name, value) => {
  localStorage.setItem(name, value);
};
export const removeLocalItem = (name) => {
  localStorage.removeItem(name);
};

export const filterSection = (allData, sectionName) => {
  if (!allData) return null;
  for (const page of allData) {
    for (const section of page.sections) {
      if (section.type === sectionName) {
        return section;
      }
    }
  }
  return null;
};

export const updatePageListData = (allData, sectionName, key, value, image) => {
  const newData = allData.map((page) => {
    return {
      ...page,
      sections: page.sections.map((section, index) => {
        if (section.type === sectionName)
          if (image) {
            return {
              ...section,
              [key]: value,
              section_image: image,
            };
          } else {
            return {
              ...section,
              [key]: value,
            };
          }
        else {
          return section;
        }
      }),
    };
  });
  return newData;
};
